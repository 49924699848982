<!--
Copyright 2019 The Ground Authors.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
-->

<google-map
  height="100%"
  width="100%"
  [options]="mapOptions"
  (mapClick)="onMapClick($event)"
>
</google-map>

<div
  *ngIf="shouldEnableDrawingTools && showRepositionConfirmDialog"
  id="reposition-confirm-dialog"
  class="reposition-confirm-dialog"
>
  <div class="dialog-content">
    <div class="dialog-text">Drag point to reposition</div>
    <button
      class="dialog-button"
      id="cancel-reposition"
      mat-flat-button
      (click)="onCancelRepositionClick()"
    >
      <label>Cancel</label>
    </button>
    <button
      class="dialog-button"
      id="confirm-reposition"
      mat-flat-button
      (click)="onSaveRepositionClick()"
    >
      <label>Save</label>
    </button>
  </div>
</div>
