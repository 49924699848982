<!--
  Copyright 2019 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<inline-editor
  mat-dialog-title
  [data]="jobName"
  placeholder="Unnamed job"
  (focusOut)="setJobName($event)"
></inline-editor>
<hr class="horizontal-line" />
<mat-dialog-content>
  <div class="options">
    <div class="permissions">
      Permissions
      <!-- <label>
        <mat-checkbox
          [(ngModel)]="dataCollectorsCanAddPoints"
          color="primary"
        >
          Data collectors may add points
        </mat-checkbox>
      </label>
      <label>
        <mat-checkbox
          [(ngModel)]="dataCollectorsCanAddPolygons"
          color="primary"
        >
          Data collectors may add polygons
        </mat-checkbox>
      </label> -->
    </div>
    <div class="default-style">
      Default style
      <ground-edit-style-button
        [markerColor]="job!.color || defaultJobColor"
        (markerColorChange)="onMarkerColorChange($event)"
      ></ground-edit-style-button>
    </div>
  </div>
  <div>
    <h3>Task Layout</h3>
    <div class="label-helper-text">
      Add questions that should be asked to observers about each point, line, or
      polygon in this job.
    </div>
  </div>
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      *ngFor="let task of tasks; let i = index; trackBy: trackByFn"
      cdkDrag
    >
      <ground-task-editor
        [label]="task.label"
        [required]="task.required"
        [taskType]="task.type"
        [cardinality]="task.multipleChoice?.cardinality"
        [multipleChoice]="task.multipleChoice"
        [taskCount]="tasks.size"
        (update)="onTaskUpdate($event, i)"
        (delete)="onTaskDelete(i)"
      >
      </ground-task-editor>
    </div>
  </div>
  <button mat-button class="add-question" type="button" (click)="addQuestion()">
    <mat-icon color="primary">add</mat-icon>
    <span class="link-add-question">Add question</span>
  </button>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" (click)="onSave()">
      Save
    </button>
  </div>
</mat-dialog-actions>
