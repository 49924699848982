<!--
  Copyright 2020 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<form [formGroup]="optionGroup">
  <div class="option-content">
    <mat-icon cdkDragHandle class="drag-icon">drag_indicator</mat-icon>
    <mat-icon class="dot">radio_button_unchecked</mat-icon>
    <mat-form-field class="task-option">
      <mat-label>Option</mat-label>
      <input
        #optionInput
        matInput
        formControlName="label"
        autocomplete="off"
        (blur)="onLabelBlur()"
      />
      <mat-error *ngIf="labelControl.touched && labelControl.invalid">
        <ng-container *ngIf="labelControl.getError('required')">
          Option is required
        </ng-container>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="code-option">
      <mat-label>Code</mat-label>
      <input
        matInput
        formControlName="code"
        autocomplete="off"
        (blur)="onCodeBlur()"
      />
    </mat-form-field>
    <button
      *ngIf="index"
      tabindex="-1"
      mat-icon-button
      (click)="onDeleteOption(index)"
      matTooltip="Delete Option"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</form>
