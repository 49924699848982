<!--
Copyright 2020 The Ground Authors.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
-->
<mat-list *ngIf="job">
  <!--
    TODO: create separate component for job as a header instead of
    using JobListItem here.
  -->
  <ground-loi-panel-header [job]="job"> </ground-loi-panel-header>
</mat-list>
<div *ngIf="job" class="button-container">
  <button
    *ngIf="!job.tasks?.isEmpty()"
    mat-flat-button
    color="primary"
    class="add-submission-btn"
    (click)="onAddSubmissionClick()"
  >
    <mat-icon class="add-submission-icon">add</mat-icon>
    Add submission
  </button>
</div>
<div *ngIf="submissions$ | async as submissions">
  <ng-container *ngIf="submissions.size; else noSubmissions">
    <mat-card appearance="outlined" class="submission card" *ngFor="let submission of submissions">
      <mat-card-header>
        <mat-card-title>
          {{ submission.created.user.displayName }}
        </mat-card-title>
        <!--TODO: figure out why serverTime is not working-->
        <mat-card-subtitle>
          {{ submission.created.clientTime | date: 'medium' }}
        </mat-card-subtitle>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="actions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onEditSubmissionClick(submission)">
            <mat-icon>edit</mat-icon>
            <span>Edit submission</span>
          </button>
          <button
            mat-menu-item
            (click)="onDeleteSubmissionClick(submission.id)"
          >
            <mat-icon>delete_forever</mat-icon>
            <span>Delete submission</span>
          </button>
        </mat-menu>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let task of getTasks(submission)">
          <label class="task-label">{{ task.label }}</label>
          <div
            class="task-result"
            *ngIf="task.type == taskTypes.TEXT || task.type == taskTypes.NUMBER"
          >
            {{ submission.data.get(task.id)?.value }}
          </div>
          <div
            class="task-result"
            *ngIf="task.type == taskTypes.MULTIPLE_CHOICE"
          >
            <span
              class="option-label"
              *ngFor="let option of getOptions(task, submission)"
            >
              {{ option.label }}
            </span>
          </div>
          <div class="task-result" *ngIf="task.type == taskTypes.PHOTO">
            <img
              (click)="openUrlInNewTab(photoUrls.get(task.id) || '')"
              src="{{ photoUrls.get(task.id) }}"
              width="100%"
            />
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-template #noSubmissions>
    <div class="no-submission-text">
      No submissions found for the selected loi.
    </div>
  </ng-template>
</div>
