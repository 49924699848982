<!--
  Copyright 2023 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content [ngSwitch]="data.dialogType">
  <mat-form-field
    *ngSwitchCase="[DialogType.AddJob, DialogType.RenameJob].includes(data.dialogType)
      ? data.dialogType
      : ''
  ">
    <mat-label>Job name</mat-label>

    <input [id]="jobNameFieldId" matInput [(ngModel)]="data.jobName" />
  </mat-form-field>

  <p *ngSwitchCase="DialogType.DeleteJob">
    This job and all of its associated data will be deleted. This operation
    can’t be undone. Are you sure?
  </p>

  <p *ngSwitchCase="DialogType.UndoJobs">
    If you leave this page, changes you’ve made to this survey won’t be
    published. Are you sure you want to continue?
  </p>

  <p *ngSwitchCase="DialogType.DeleteLois">
    All predefined data collection sites and their associated data will
    be immediately deleted. This action cannot be undone.
  </p>

  <p *ngSwitchCase="DialogType.DeleteOption">
    Are you sure you wish to delete this option?
    All associated data will be lost. This cannot be undone.
  </p>

  <p *ngSwitchCase="DialogType.DeleteSurvey">
    Are you sure you wish to delete this survey?
    All associated data will be lost. This cannot be undone.
  </p>

  <p *ngSwitchCase="DialogType.DisableFreeForm">
    Data collector will no longer be able to add new sites for this job.
    Data will only be collected for existing sites.
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    color="primary"
    mat-dialog-close
  >
    Cancel
  </button>

  <button
    mat-button
    color="primary"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    {{ buttonLabel }}
  </button>
</mat-dialog-actions>
