<!--
  Copyright 2023 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<h1 class="title">Add a job</h1>
<h2 class="description">
  In the following steps, you'll define what data should be collected for which sites
  as part of this job.
</h2>
<form [formGroup]="formGroup">
  <mat-form-field class="input" appearance="fill">
    <mat-label> Job name </mat-label>
    <input 
      matInput [formControlName]="nameControlKey"
      placeholder="Ex. Map production areas" />
  </mat-form-field>
</form>
