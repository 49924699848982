<!--
  Copyright 2023 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<div class="survey-details-container">

  <mat-card class="survey-detail-card">
    <mat-card-content>
      <div class="card-title">Survey name and description</div>

      <div class="card-row edit-survey-details">
        <survey-details
          #surveyDetails
          [title]="survey?.title"
          [description]="survey?.description"
          (onValueChanges)="onDetailsChange($event)"
        ></survey-details>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="survey-detail-card" *ngIf="survey?.dataSharingTerms as terms">
    <mat-card-content>
      <div class="card-title">Data sharing agreement</div>
      <div class="card-row">
        <p [innerHtml]="dataSharingTermsDetails.descriptionHtml"></p>
        <div *ngIf="dataSharingTermsDetails.customText as customText">
          <div class="card-subtitle">Custom terms</div>
          <p>
            {{ customText }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="survey-detail-card">
    <mat-card-content>
      <div class="card-title">Delete survey</div>
      <div class="card-row delete-survey">
        <div>Permanently delete survey and associated data</div>

        <button mat-flat-button (click)="openDeleteSurveyDialog()">
          Delete survey
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
