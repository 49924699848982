<!--
  Copyright 2023 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<div class="page">
  <ground-survey-header></ground-survey-header>

  <!-- TODO(#1170): Extract the spinner into a component -->
  <div id="loading-spinner" class="loading-spinner" *ngIf="!survey">
    <div>
      <mat-spinner></mat-spinner>
      <p class="loading-label">Loading survey...</p>
    </div>
  </div>
  <div *ngIf="survey" class="container">
    <mat-nav-list class="left-menu">
      <a
        mat-list-item
        id="survey-button"
        [routerLink]="['./survey']"
        [routerLinkActive]="['is-active']"
      >
        <mat-icon class="material-symbols-outlined button-icon" matListItemIcon>
          quick_reference_all
        </mat-icon>
        <span matListItemTitle>Survey details</span>
      </a>

      <a
        mat-list-item
        id="share-button"
        [routerLink]="['./share']"
        [routerLinkActive]="['is-active']"
      >
        <mat-icon class="material-symbols-outlined button-icon" matListItemIcon>
          group
        </mat-icon>
        <span matListItemTitle>Sharing</span>
      </a>

      <a
        *ngIf="!production"
        mat-list-item
        id="survey-json"
        [routerLink]="['./json']"
        [routerLinkActive]="['is-active']"
      >
        <mat-icon class="material-symbols-outlined button-icon" matListItemIcon>
          code
        </mat-icon>
        <span matListItemTitle>JSON</span>
      </a>

      <div class="job-label-and-add-button">
        <h3 matSubheader class="job-label">Jobs</h3>
        <button
          mat-icon-button
          id="add-button"
          (click)="addJob()"
        >
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </button>
      </div>

      <a
        *ngFor="let job of jobs(); index as i"
        mat-list-item
        id="job-{{ i }}"
        class="job-list-item"
        [routerLink]="jobRouterLink(job.id)"
        [routerLinkActive]="['is-active']"
      >
        <mat-icon class="icon" [ngStyle]="{ color: job.color }" matListItemIcon>
          label
        </mat-icon>

        <span class="name" matListItemTitle>{{ job.name }}</span>

        <button
          mat-icon-button
          id="menu-button-{{ i }}"
          [matMenuTriggerFor]="menu"
          (click)="onMenu($event)"
          matListItemMeta
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <button
            mat-menu-item
            id="rename-button-{{ i }}"
            (click)="renameJob(job)"
          >
            Rename
          </button>
          <button
            mat-menu-item
            id="duplicate-button-{{ i }}"
            (click)="duplicateJob(job)"
          >
            Duplicate
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            id="delete-button-{{ i }}"
            (click)="deleteJob(job)"
          >
            Remove
          </button>
        </mat-menu>
      </a>
    </mat-nav-list>

    <div class="card">
      <div class="content">
        <h1 class="edit-survey-title" *ngIf="sectionTitle !== ''">
          {{ sectionTitle }}
        </h1>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
