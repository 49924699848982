<!--
Copyright 2023 The Ground Authors.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
-->

<div class="submission-panel" [ngClass]="{loading: isLoading}">
  <div *ngIf="isLoading" class="progress-spinner">
    <mat-spinner mode="indeterminate" diameter="50"></mat-spinner>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="submission-title">
      <div class="submission-title-left">
        <button mat-icon-button (click)="navigateToSubmissionList()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="submission-title-text">{{submission?.created?.user?.displayName}}</div>
      </div>
    </div>
    <div class="submission-subtitle">
      {{submission?.lastModified?.clientTime | date: 'longDate' }} • 
      {{submission?.lastModified?.clientTime | date: 'shortTime' }}
    </div>
    <div *ngIf="tasks" class="submission-tasks-container">
      <div *ngFor="let task of tasks; let i = index">
        <ng-container *ngIf="getTaskSubmissionResult(task) !== undefined">
          <mat-divider *ngIf="i > 0"></mat-divider>
          <div class="submission-task">
            <!-- Question -->
            <div class="submission-question">{{task.label}}</div>
            <!-- Response -->
            <div class="submission-response">
              <div *ngIf="getTaskSubmissionResult(task).skipped" class="submission-no-response">
                Skipped
              </div>
              <ng-container *ngIf="!getTaskSubmissionResult(task).skipped">
                <!-- Multiple choice task types -->
                <div *ngIf="task.type === taskType.MULTIPLE_CHOICE" class="submission-response-multiple-choice">
                  <mat-checkbox disabled="true" checked="true" *ngFor="let optionId of getTaskMultipleChoiceSelections(task).values">
                    {{getMultipleChoiceOption(task, optionId).label}}
                  </mat-checkbox>
                  <mat-checkbox disabled="true" checked="true" *ngIf="getTaskMultipleChoiceOtherValue(task) as otherValue">
                    {{otherValue}}
                  </mat-checkbox>
                </div>
                <!-- Photo task types -->
                <div *ngIf="task.type === taskType.PHOTO">
                  <img src="{{this.firebaseURLs.get(getTaskSubmissionResult(task)!.value)}}" class="submission-img" alt="Image could not be loaded"/>
                </div>
                <!-- Geometry task types -->
                <div *ngIf="task.type === taskType.DROP_PIN || task.type === taskType.CAPTURE_LOCATION || task.type === taskType.DRAW_AREA">
                  <div class="submission-response-geometry" [ngClass]="{'selected-submission-response-geometry': task.id === selectedTaskId}">
                    <div class="submission-response-geometry-number" [ngStyle]="{ 'background-color': this.submission?.job.color ?? 'black' }">
                      {{i}}
                    </div>
                    <div *ngIf="task.type === taskType.DROP_PIN || task.type === taskType.DRAW_AREA" class="submission-response-geometry-label">
                      {{task.id}}
                    </div>
                    <div *ngIf="task.type === taskType.CAPTURE_LOCATION" class="submission-response-geometry-label">
                      {{getCaptureLocationCoord(task)}}
                    </div>
                  </div>
                </div>
                <!-- Date task types -->
                <div *ngIf="task.type === taskType.DATE">
                  {{getDate(task)}}
                </div>
                <!-- Time task types -->
                <div *ngIf="task.type === taskType.TIME">
                  {{getTime(task)}}
                </div>
                <!-- Remaining task types -->
                <div *ngIf="task.type === taskType.TEXT || task.type === taskType.NUMBER || task.type === taskType.DATE_TIME">
                  {{getTaskSubmissionResult(task)!.value.toString()}}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
