/**
 * Copyright 2023 The Ground Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {List} from 'immutable';

export enum TaskConditionMatchType {
  MATCH_ALL = 'MATCH_ALL',
}

export enum TaskConditionExpressionType {
  ONE_OF_SELECTED = 'ONE_OF_SELECTED',
}

export class TaskConditionExpression {
  constructor(
    readonly expressionType: TaskConditionExpressionType,
    readonly taskId: string,
    readonly optionIds: List<string>
  ) {}
}

export class TaskCondition {
  constructor(
    readonly matchType: TaskConditionMatchType,
    readonly expressions: List<TaskConditionExpression>
  ) {}
}
