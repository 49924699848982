<!--
  Copyright 2020 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<h1 mat-dialog-title>Share survey</h1>

<div mat-dialog-content>
  <h2 class="share-dialog-description">Who do you want to invite for the survey?</h2>
  <form class="share-form" [formGroup]="addUserForm" (ngSubmit)="onAddUserSubmit()">
    <mat-form-field class="email-input">
      <mat-label>Email address</mat-label>
      <input matInput formControlName="email" autocomplete="off" />
      <mat-error *ngIf="emailControl.touched && emailControl.invalid">
        <ng-container *ngIf="emailControl.getError('email')">
          Invalid email format
        </ng-container>
        <ng-container *ngIf="emailControl.getError('forbiddenName')">
          Email address already added
        </ng-container>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="role">
        <mat-option
          *ngFor="let option of roleOptions"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
  <div mat-dialog-actions align="end">
    <button mat-button mat-flat-button (click)="onCancelClicked()">
      <label>Cancel</label>
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="addUserForm.value['email'] == '' || !addUserForm.valid"
      (click)="onAddUserSubmit()"
    >
      <label>Done</label>
    </button>
  </div>
</div>
