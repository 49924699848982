/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.ground = (function() {
    
        /**
         * Namespace ground.
         * @exports ground
         * @namespace
         */
        var ground = {};
    
        ground.v1beta1 = (function() {
    
            /**
             * Namespace v1beta1.
             * @memberof ground
             * @namespace
             */
            var v1beta1 = {};
    
            v1beta1.AuditInfo = (function() {
    
                /**
                 * Properties of an AuditInfo.
                 * @memberof ground.v1beta1
                 * @interface IAuditInfo
                 * @property {string|null} [userId] AuditInfo userId
                 * @property {google.protobuf.ITimestamp|null} [clientTimestamp] AuditInfo clientTimestamp
                 * @property {google.protobuf.ITimestamp|null} [serverTimestamp] AuditInfo serverTimestamp
                 * @property {string|null} [displayName] AuditInfo displayName
                 * @property {string|null} [photoUrl] AuditInfo photoUrl
                 * @property {string|null} [emailAddress] AuditInfo emailAddress
                 */
    
                /**
                 * Constructs a new AuditInfo.
                 * @memberof ground.v1beta1
                 * @classdesc Represents an AuditInfo.
                 * @implements IAuditInfo
                 * @constructor
                 * @param {ground.v1beta1.IAuditInfo=} [properties] Properties to set
                 */
                function AuditInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AuditInfo userId.
                 * @member {string} userId
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.userId = "";
    
                /**
                 * AuditInfo clientTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} clientTimestamp
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.clientTimestamp = null;
    
                /**
                 * AuditInfo serverTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} serverTimestamp
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.serverTimestamp = null;
    
                /**
                 * AuditInfo displayName.
                 * @member {string} displayName
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.displayName = "";
    
                /**
                 * AuditInfo photoUrl.
                 * @member {string} photoUrl
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.photoUrl = "";
    
                /**
                 * AuditInfo emailAddress.
                 * @member {string} emailAddress
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 */
                AuditInfo.prototype.emailAddress = "";
    
                /**
                 * Creates a new AuditInfo instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {ground.v1beta1.IAuditInfo=} [properties] Properties to set
                 * @returns {ground.v1beta1.AuditInfo} AuditInfo instance
                 */
                AuditInfo.create = function create(properties) {
                    return new AuditInfo(properties);
                };
    
                /**
                 * Encodes the specified AuditInfo message. Does not implicitly {@link ground.v1beta1.AuditInfo.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {ground.v1beta1.IAuditInfo} message AuditInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.clientTimestamp != null && Object.hasOwnProperty.call(message, "clientTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.clientTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.serverTimestamp != null && Object.hasOwnProperty.call(message, "serverTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.serverTimestamp, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.displayName);
                    if (message.photoUrl != null && Object.hasOwnProperty.call(message, "photoUrl"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.photoUrl);
                    if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.emailAddress);
                    return writer;
                };
    
                /**
                 * Encodes the specified AuditInfo message, length delimited. Does not implicitly {@link ground.v1beta1.AuditInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {ground.v1beta1.IAuditInfo} message AuditInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AuditInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AuditInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.AuditInfo} AuditInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.AuditInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.clientTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.serverTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.displayName = reader.string();
                                break;
                            }
                        case 5: {
                                message.photoUrl = reader.string();
                                break;
                            }
                        case 6: {
                                message.emailAddress = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AuditInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.AuditInfo} AuditInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AuditInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AuditInfo message.
                 * @function verify
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AuditInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.clientTimestamp);
                        if (error)
                            return "clientTimestamp." + error;
                    }
                    if (message.serverTimestamp != null && message.hasOwnProperty("serverTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.serverTimestamp);
                        if (error)
                            return "serverTimestamp." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        if (!$util.isString(message.photoUrl))
                            return "photoUrl: string expected";
                    if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                        if (!$util.isString(message.emailAddress))
                            return "emailAddress: string expected";
                    return null;
                };
    
                /**
                 * Creates an AuditInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.AuditInfo} AuditInfo
                 */
                AuditInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.AuditInfo)
                        return object;
                    var message = new $root.ground.v1beta1.AuditInfo();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.clientTimestamp != null) {
                        if (typeof object.clientTimestamp !== "object")
                            throw TypeError(".ground.v1beta1.AuditInfo.clientTimestamp: object expected");
                        message.clientTimestamp = $root.google.protobuf.Timestamp.fromObject(object.clientTimestamp);
                    }
                    if (object.serverTimestamp != null) {
                        if (typeof object.serverTimestamp !== "object")
                            throw TypeError(".ground.v1beta1.AuditInfo.serverTimestamp: object expected");
                        message.serverTimestamp = $root.google.protobuf.Timestamp.fromObject(object.serverTimestamp);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.photoUrl != null)
                        message.photoUrl = String(object.photoUrl);
                    if (object.emailAddress != null)
                        message.emailAddress = String(object.emailAddress);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AuditInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {ground.v1beta1.AuditInfo} message AuditInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AuditInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.clientTimestamp = null;
                        object.serverTimestamp = null;
                        object.displayName = "";
                        object.photoUrl = "";
                        object.emailAddress = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        object.clientTimestamp = $root.google.protobuf.Timestamp.toObject(message.clientTimestamp, options);
                    if (message.serverTimestamp != null && message.hasOwnProperty("serverTimestamp"))
                        object.serverTimestamp = $root.google.protobuf.Timestamp.toObject(message.serverTimestamp, options);
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.photoUrl != null && message.hasOwnProperty("photoUrl"))
                        object.photoUrl = message.photoUrl;
                    if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                        object.emailAddress = message.emailAddress;
                    return object;
                };
    
                /**
                 * Converts this AuditInfo to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.AuditInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AuditInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for AuditInfo
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.AuditInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AuditInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.AuditInfo";
                };
    
                return AuditInfo;
            })();
    
            v1beta1.Geometry = (function() {
    
                /**
                 * Properties of a Geometry.
                 * @memberof ground.v1beta1
                 * @interface IGeometry
                 * @property {ground.v1beta1.IPoint|null} [point] Geometry point
                 * @property {ground.v1beta1.IPolygon|null} [polygon] Geometry polygon
                 * @property {ground.v1beta1.IMultiPolygon|null} [multiPolygon] Geometry multiPolygon
                 */
    
                /**
                 * Constructs a new Geometry.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Geometry.
                 * @implements IGeometry
                 * @constructor
                 * @param {ground.v1beta1.IGeometry=} [properties] Properties to set
                 */
                function Geometry(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Geometry point.
                 * @member {ground.v1beta1.IPoint|null|undefined} point
                 * @memberof ground.v1beta1.Geometry
                 * @instance
                 */
                Geometry.prototype.point = null;
    
                /**
                 * Geometry polygon.
                 * @member {ground.v1beta1.IPolygon|null|undefined} polygon
                 * @memberof ground.v1beta1.Geometry
                 * @instance
                 */
                Geometry.prototype.polygon = null;
    
                /**
                 * Geometry multiPolygon.
                 * @member {ground.v1beta1.IMultiPolygon|null|undefined} multiPolygon
                 * @memberof ground.v1beta1.Geometry
                 * @instance
                 */
                Geometry.prototype.multiPolygon = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Geometry geometryType.
                 * @member {"point"|"polygon"|"multiPolygon"|undefined} geometryType
                 * @memberof ground.v1beta1.Geometry
                 * @instance
                 */
                Object.defineProperty(Geometry.prototype, "geometryType", {
                    get: $util.oneOfGetter($oneOfFields = ["point", "polygon", "multiPolygon"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Geometry instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {ground.v1beta1.IGeometry=} [properties] Properties to set
                 * @returns {ground.v1beta1.Geometry} Geometry instance
                 */
                Geometry.create = function create(properties) {
                    return new Geometry(properties);
                };
    
                /**
                 * Encodes the specified Geometry message. Does not implicitly {@link ground.v1beta1.Geometry.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {ground.v1beta1.IGeometry} message Geometry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Geometry.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.point != null && Object.hasOwnProperty.call(message, "point"))
                        $root.ground.v1beta1.Point.encode(message.point, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                        $root.ground.v1beta1.Polygon.encode(message.polygon, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.multiPolygon != null && Object.hasOwnProperty.call(message, "multiPolygon"))
                        $root.ground.v1beta1.MultiPolygon.encode(message.multiPolygon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Geometry message, length delimited. Does not implicitly {@link ground.v1beta1.Geometry.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {ground.v1beta1.IGeometry} message Geometry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Geometry.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Geometry message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Geometry} Geometry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Geometry.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Geometry();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.point = $root.ground.v1beta1.Point.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.polygon = $root.ground.v1beta1.Polygon.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.multiPolygon = $root.ground.v1beta1.MultiPolygon.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Geometry message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Geometry} Geometry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Geometry.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Geometry message.
                 * @function verify
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Geometry.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.point != null && message.hasOwnProperty("point")) {
                        properties.geometryType = 1;
                        {
                            var error = $root.ground.v1beta1.Point.verify(message.point);
                            if (error)
                                return "point." + error;
                        }
                    }
                    if (message.polygon != null && message.hasOwnProperty("polygon")) {
                        if (properties.geometryType === 1)
                            return "geometryType: multiple values";
                        properties.geometryType = 1;
                        {
                            var error = $root.ground.v1beta1.Polygon.verify(message.polygon);
                            if (error)
                                return "polygon." + error;
                        }
                    }
                    if (message.multiPolygon != null && message.hasOwnProperty("multiPolygon")) {
                        if (properties.geometryType === 1)
                            return "geometryType: multiple values";
                        properties.geometryType = 1;
                        {
                            var error = $root.ground.v1beta1.MultiPolygon.verify(message.multiPolygon);
                            if (error)
                                return "multiPolygon." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Geometry message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Geometry} Geometry
                 */
                Geometry.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Geometry)
                        return object;
                    var message = new $root.ground.v1beta1.Geometry();
                    if (object.point != null) {
                        if (typeof object.point !== "object")
                            throw TypeError(".ground.v1beta1.Geometry.point: object expected");
                        message.point = $root.ground.v1beta1.Point.fromObject(object.point);
                    }
                    if (object.polygon != null) {
                        if (typeof object.polygon !== "object")
                            throw TypeError(".ground.v1beta1.Geometry.polygon: object expected");
                        message.polygon = $root.ground.v1beta1.Polygon.fromObject(object.polygon);
                    }
                    if (object.multiPolygon != null) {
                        if (typeof object.multiPolygon !== "object")
                            throw TypeError(".ground.v1beta1.Geometry.multiPolygon: object expected");
                        message.multiPolygon = $root.ground.v1beta1.MultiPolygon.fromObject(object.multiPolygon);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Geometry message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {ground.v1beta1.Geometry} message Geometry
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Geometry.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.point != null && message.hasOwnProperty("point")) {
                        object.point = $root.ground.v1beta1.Point.toObject(message.point, options);
                        if (options.oneofs)
                            object.geometryType = "point";
                    }
                    if (message.polygon != null && message.hasOwnProperty("polygon")) {
                        object.polygon = $root.ground.v1beta1.Polygon.toObject(message.polygon, options);
                        if (options.oneofs)
                            object.geometryType = "polygon";
                    }
                    if (message.multiPolygon != null && message.hasOwnProperty("multiPolygon")) {
                        object.multiPolygon = $root.ground.v1beta1.MultiPolygon.toObject(message.multiPolygon, options);
                        if (options.oneofs)
                            object.geometryType = "multiPolygon";
                    }
                    return object;
                };
    
                /**
                 * Converts this Geometry to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Geometry
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Geometry.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Geometry
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Geometry
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Geometry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Geometry";
                };
    
                return Geometry;
            })();
    
            v1beta1.Coordinates = (function() {
    
                /**
                 * Properties of a Coordinates.
                 * @memberof ground.v1beta1
                 * @interface ICoordinates
                 * @property {number|null} [latitude] Coordinates latitude
                 * @property {number|null} [longitude] Coordinates longitude
                 */
    
                /**
                 * Constructs a new Coordinates.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Coordinates.
                 * @implements ICoordinates
                 * @constructor
                 * @param {ground.v1beta1.ICoordinates=} [properties] Properties to set
                 */
                function Coordinates(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Coordinates latitude.
                 * @member {number} latitude
                 * @memberof ground.v1beta1.Coordinates
                 * @instance
                 */
                Coordinates.prototype.latitude = 0;
    
                /**
                 * Coordinates longitude.
                 * @member {number} longitude
                 * @memberof ground.v1beta1.Coordinates
                 * @instance
                 */
                Coordinates.prototype.longitude = 0;
    
                /**
                 * Creates a new Coordinates instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {ground.v1beta1.ICoordinates=} [properties] Properties to set
                 * @returns {ground.v1beta1.Coordinates} Coordinates instance
                 */
                Coordinates.create = function create(properties) {
                    return new Coordinates(properties);
                };
    
                /**
                 * Encodes the specified Coordinates message. Does not implicitly {@link ground.v1beta1.Coordinates.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {ground.v1beta1.ICoordinates} message Coordinates message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Coordinates.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
                    if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                    return writer;
                };
    
                /**
                 * Encodes the specified Coordinates message, length delimited. Does not implicitly {@link ground.v1beta1.Coordinates.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {ground.v1beta1.ICoordinates} message Coordinates message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Coordinates.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Coordinates message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Coordinates} Coordinates
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Coordinates.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Coordinates();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.latitude = reader.double();
                                break;
                            }
                        case 2: {
                                message.longitude = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Coordinates message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Coordinates} Coordinates
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Coordinates.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Coordinates message.
                 * @function verify
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Coordinates.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.latitude != null && message.hasOwnProperty("latitude"))
                        if (typeof message.latitude !== "number")
                            return "latitude: number expected";
                    if (message.longitude != null && message.hasOwnProperty("longitude"))
                        if (typeof message.longitude !== "number")
                            return "longitude: number expected";
                    return null;
                };
    
                /**
                 * Creates a Coordinates message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Coordinates} Coordinates
                 */
                Coordinates.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Coordinates)
                        return object;
                    var message = new $root.ground.v1beta1.Coordinates();
                    if (object.latitude != null)
                        message.latitude = Number(object.latitude);
                    if (object.longitude != null)
                        message.longitude = Number(object.longitude);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Coordinates message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {ground.v1beta1.Coordinates} message Coordinates
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Coordinates.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.latitude = 0;
                        object.longitude = 0;
                    }
                    if (message.latitude != null && message.hasOwnProperty("latitude"))
                        object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                    if (message.longitude != null && message.hasOwnProperty("longitude"))
                        object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                    return object;
                };
    
                /**
                 * Converts this Coordinates to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Coordinates
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Coordinates.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Coordinates
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Coordinates
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Coordinates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Coordinates";
                };
    
                return Coordinates;
            })();
    
            v1beta1.Point = (function() {
    
                /**
                 * Properties of a Point.
                 * @memberof ground.v1beta1
                 * @interface IPoint
                 * @property {ground.v1beta1.ICoordinates|null} [coordinates] Point coordinates
                 */
    
                /**
                 * Constructs a new Point.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Point.
                 * @implements IPoint
                 * @constructor
                 * @param {ground.v1beta1.IPoint=} [properties] Properties to set
                 */
                function Point(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Point coordinates.
                 * @member {ground.v1beta1.ICoordinates|null|undefined} coordinates
                 * @memberof ground.v1beta1.Point
                 * @instance
                 */
                Point.prototype.coordinates = null;
    
                /**
                 * Creates a new Point instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {ground.v1beta1.IPoint=} [properties] Properties to set
                 * @returns {ground.v1beta1.Point} Point instance
                 */
                Point.create = function create(properties) {
                    return new Point(properties);
                };
    
                /**
                 * Encodes the specified Point message. Does not implicitly {@link ground.v1beta1.Point.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {ground.v1beta1.IPoint} message Point message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Point.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                        $root.ground.v1beta1.Coordinates.encode(message.coordinates, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Point message, length delimited. Does not implicitly {@link ground.v1beta1.Point.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {ground.v1beta1.IPoint} message Point message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Point.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Point message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Point} Point
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Point.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Point();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.coordinates = $root.ground.v1beta1.Coordinates.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Point message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Point} Point
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Point.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Point message.
                 * @function verify
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Point.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                        var error = $root.ground.v1beta1.Coordinates.verify(message.coordinates);
                        if (error)
                            return "coordinates." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a Point message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Point} Point
                 */
                Point.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Point)
                        return object;
                    var message = new $root.ground.v1beta1.Point();
                    if (object.coordinates != null) {
                        if (typeof object.coordinates !== "object")
                            throw TypeError(".ground.v1beta1.Point.coordinates: object expected");
                        message.coordinates = $root.ground.v1beta1.Coordinates.fromObject(object.coordinates);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Point message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {ground.v1beta1.Point} message Point
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Point.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.coordinates = null;
                    if (message.coordinates != null && message.hasOwnProperty("coordinates"))
                        object.coordinates = $root.ground.v1beta1.Coordinates.toObject(message.coordinates, options);
                    return object;
                };
    
                /**
                 * Converts this Point to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Point
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Point.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Point
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Point
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Point.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Point";
                };
    
                return Point;
            })();
    
            v1beta1.LinearRing = (function() {
    
                /**
                 * Properties of a LinearRing.
                 * @memberof ground.v1beta1
                 * @interface ILinearRing
                 * @property {Array.<ground.v1beta1.ICoordinates>|null} [coordinates] LinearRing coordinates
                 */
    
                /**
                 * Constructs a new LinearRing.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a LinearRing.
                 * @implements ILinearRing
                 * @constructor
                 * @param {ground.v1beta1.ILinearRing=} [properties] Properties to set
                 */
                function LinearRing(properties) {
                    this.coordinates = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LinearRing coordinates.
                 * @member {Array.<ground.v1beta1.ICoordinates>} coordinates
                 * @memberof ground.v1beta1.LinearRing
                 * @instance
                 */
                LinearRing.prototype.coordinates = $util.emptyArray;
    
                /**
                 * Creates a new LinearRing instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {ground.v1beta1.ILinearRing=} [properties] Properties to set
                 * @returns {ground.v1beta1.LinearRing} LinearRing instance
                 */
                LinearRing.create = function create(properties) {
                    return new LinearRing(properties);
                };
    
                /**
                 * Encodes the specified LinearRing message. Does not implicitly {@link ground.v1beta1.LinearRing.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {ground.v1beta1.ILinearRing} message LinearRing message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LinearRing.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.coordinates != null && message.coordinates.length)
                        for (var i = 0; i < message.coordinates.length; ++i)
                            $root.ground.v1beta1.Coordinates.encode(message.coordinates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified LinearRing message, length delimited. Does not implicitly {@link ground.v1beta1.LinearRing.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {ground.v1beta1.ILinearRing} message LinearRing message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LinearRing.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LinearRing message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.LinearRing} LinearRing
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LinearRing.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.LinearRing();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.coordinates && message.coordinates.length))
                                    message.coordinates = [];
                                message.coordinates.push($root.ground.v1beta1.Coordinates.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LinearRing message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.LinearRing} LinearRing
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LinearRing.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LinearRing message.
                 * @function verify
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LinearRing.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                        if (!Array.isArray(message.coordinates))
                            return "coordinates: array expected";
                        for (var i = 0; i < message.coordinates.length; ++i) {
                            var error = $root.ground.v1beta1.Coordinates.verify(message.coordinates[i]);
                            if (error)
                                return "coordinates." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a LinearRing message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.LinearRing} LinearRing
                 */
                LinearRing.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.LinearRing)
                        return object;
                    var message = new $root.ground.v1beta1.LinearRing();
                    if (object.coordinates) {
                        if (!Array.isArray(object.coordinates))
                            throw TypeError(".ground.v1beta1.LinearRing.coordinates: array expected");
                        message.coordinates = [];
                        for (var i = 0; i < object.coordinates.length; ++i) {
                            if (typeof object.coordinates[i] !== "object")
                                throw TypeError(".ground.v1beta1.LinearRing.coordinates: object expected");
                            message.coordinates[i] = $root.ground.v1beta1.Coordinates.fromObject(object.coordinates[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LinearRing message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {ground.v1beta1.LinearRing} message LinearRing
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LinearRing.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.coordinates = [];
                    if (message.coordinates && message.coordinates.length) {
                        object.coordinates = [];
                        for (var j = 0; j < message.coordinates.length; ++j)
                            object.coordinates[j] = $root.ground.v1beta1.Coordinates.toObject(message.coordinates[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this LinearRing to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.LinearRing
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LinearRing.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LinearRing
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.LinearRing
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LinearRing.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.LinearRing";
                };
    
                return LinearRing;
            })();
    
            v1beta1.Polygon = (function() {
    
                /**
                 * Properties of a Polygon.
                 * @memberof ground.v1beta1
                 * @interface IPolygon
                 * @property {ground.v1beta1.ILinearRing|null} [shell] Polygon shell
                 * @property {Array.<ground.v1beta1.ILinearRing>|null} [holes] Polygon holes
                 */
    
                /**
                 * Constructs a new Polygon.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Polygon.
                 * @implements IPolygon
                 * @constructor
                 * @param {ground.v1beta1.IPolygon=} [properties] Properties to set
                 */
                function Polygon(properties) {
                    this.holes = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Polygon shell.
                 * @member {ground.v1beta1.ILinearRing|null|undefined} shell
                 * @memberof ground.v1beta1.Polygon
                 * @instance
                 */
                Polygon.prototype.shell = null;
    
                /**
                 * Polygon holes.
                 * @member {Array.<ground.v1beta1.ILinearRing>} holes
                 * @memberof ground.v1beta1.Polygon
                 * @instance
                 */
                Polygon.prototype.holes = $util.emptyArray;
    
                /**
                 * Creates a new Polygon instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {ground.v1beta1.IPolygon=} [properties] Properties to set
                 * @returns {ground.v1beta1.Polygon} Polygon instance
                 */
                Polygon.create = function create(properties) {
                    return new Polygon(properties);
                };
    
                /**
                 * Encodes the specified Polygon message. Does not implicitly {@link ground.v1beta1.Polygon.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {ground.v1beta1.IPolygon} message Polygon message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Polygon.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.shell != null && Object.hasOwnProperty.call(message, "shell"))
                        $root.ground.v1beta1.LinearRing.encode(message.shell, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.holes != null && message.holes.length)
                        for (var i = 0; i < message.holes.length; ++i)
                            $root.ground.v1beta1.LinearRing.encode(message.holes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Polygon message, length delimited. Does not implicitly {@link ground.v1beta1.Polygon.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {ground.v1beta1.IPolygon} message Polygon message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Polygon.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Polygon message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Polygon} Polygon
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Polygon.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Polygon();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.shell = $root.ground.v1beta1.LinearRing.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.holes && message.holes.length))
                                    message.holes = [];
                                message.holes.push($root.ground.v1beta1.LinearRing.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Polygon message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Polygon} Polygon
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Polygon.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Polygon message.
                 * @function verify
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Polygon.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.shell != null && message.hasOwnProperty("shell")) {
                        var error = $root.ground.v1beta1.LinearRing.verify(message.shell);
                        if (error)
                            return "shell." + error;
                    }
                    if (message.holes != null && message.hasOwnProperty("holes")) {
                        if (!Array.isArray(message.holes))
                            return "holes: array expected";
                        for (var i = 0; i < message.holes.length; ++i) {
                            var error = $root.ground.v1beta1.LinearRing.verify(message.holes[i]);
                            if (error)
                                return "holes." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Polygon} Polygon
                 */
                Polygon.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Polygon)
                        return object;
                    var message = new $root.ground.v1beta1.Polygon();
                    if (object.shell != null) {
                        if (typeof object.shell !== "object")
                            throw TypeError(".ground.v1beta1.Polygon.shell: object expected");
                        message.shell = $root.ground.v1beta1.LinearRing.fromObject(object.shell);
                    }
                    if (object.holes) {
                        if (!Array.isArray(object.holes))
                            throw TypeError(".ground.v1beta1.Polygon.holes: array expected");
                        message.holes = [];
                        for (var i = 0; i < object.holes.length; ++i) {
                            if (typeof object.holes[i] !== "object")
                                throw TypeError(".ground.v1beta1.Polygon.holes: object expected");
                            message.holes[i] = $root.ground.v1beta1.LinearRing.fromObject(object.holes[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Polygon message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {ground.v1beta1.Polygon} message Polygon
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Polygon.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.holes = [];
                    if (options.defaults)
                        object.shell = null;
                    if (message.shell != null && message.hasOwnProperty("shell"))
                        object.shell = $root.ground.v1beta1.LinearRing.toObject(message.shell, options);
                    if (message.holes && message.holes.length) {
                        object.holes = [];
                        for (var j = 0; j < message.holes.length; ++j)
                            object.holes[j] = $root.ground.v1beta1.LinearRing.toObject(message.holes[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Polygon to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Polygon
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Polygon.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Polygon
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Polygon
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Polygon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Polygon";
                };
    
                return Polygon;
            })();
    
            v1beta1.MultiPolygon = (function() {
    
                /**
                 * Properties of a MultiPolygon.
                 * @memberof ground.v1beta1
                 * @interface IMultiPolygon
                 * @property {Array.<ground.v1beta1.IPolygon>|null} [polygons] MultiPolygon polygons
                 */
    
                /**
                 * Constructs a new MultiPolygon.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a MultiPolygon.
                 * @implements IMultiPolygon
                 * @constructor
                 * @param {ground.v1beta1.IMultiPolygon=} [properties] Properties to set
                 */
                function MultiPolygon(properties) {
                    this.polygons = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * MultiPolygon polygons.
                 * @member {Array.<ground.v1beta1.IPolygon>} polygons
                 * @memberof ground.v1beta1.MultiPolygon
                 * @instance
                 */
                MultiPolygon.prototype.polygons = $util.emptyArray;
    
                /**
                 * Creates a new MultiPolygon instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {ground.v1beta1.IMultiPolygon=} [properties] Properties to set
                 * @returns {ground.v1beta1.MultiPolygon} MultiPolygon instance
                 */
                MultiPolygon.create = function create(properties) {
                    return new MultiPolygon(properties);
                };
    
                /**
                 * Encodes the specified MultiPolygon message. Does not implicitly {@link ground.v1beta1.MultiPolygon.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {ground.v1beta1.IMultiPolygon} message MultiPolygon message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiPolygon.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.polygons != null && message.polygons.length)
                        for (var i = 0; i < message.polygons.length; ++i)
                            $root.ground.v1beta1.Polygon.encode(message.polygons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified MultiPolygon message, length delimited. Does not implicitly {@link ground.v1beta1.MultiPolygon.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {ground.v1beta1.IMultiPolygon} message MultiPolygon message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MultiPolygon.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a MultiPolygon message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.MultiPolygon} MultiPolygon
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiPolygon.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.MultiPolygon();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.polygons && message.polygons.length))
                                    message.polygons = [];
                                message.polygons.push($root.ground.v1beta1.Polygon.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a MultiPolygon message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.MultiPolygon} MultiPolygon
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MultiPolygon.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a MultiPolygon message.
                 * @function verify
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MultiPolygon.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.polygons != null && message.hasOwnProperty("polygons")) {
                        if (!Array.isArray(message.polygons))
                            return "polygons: array expected";
                        for (var i = 0; i < message.polygons.length; ++i) {
                            var error = $root.ground.v1beta1.Polygon.verify(message.polygons[i]);
                            if (error)
                                return "polygons." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a MultiPolygon message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.MultiPolygon} MultiPolygon
                 */
                MultiPolygon.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.MultiPolygon)
                        return object;
                    var message = new $root.ground.v1beta1.MultiPolygon();
                    if (object.polygons) {
                        if (!Array.isArray(object.polygons))
                            throw TypeError(".ground.v1beta1.MultiPolygon.polygons: array expected");
                        message.polygons = [];
                        for (var i = 0; i < object.polygons.length; ++i) {
                            if (typeof object.polygons[i] !== "object")
                                throw TypeError(".ground.v1beta1.MultiPolygon.polygons: object expected");
                            message.polygons[i] = $root.ground.v1beta1.Polygon.fromObject(object.polygons[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a MultiPolygon message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {ground.v1beta1.MultiPolygon} message MultiPolygon
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MultiPolygon.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.polygons = [];
                    if (message.polygons && message.polygons.length) {
                        object.polygons = [];
                        for (var j = 0; j < message.polygons.length; ++j)
                            object.polygons[j] = $root.ground.v1beta1.Polygon.toObject(message.polygons[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this MultiPolygon to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.MultiPolygon
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MultiPolygon.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for MultiPolygon
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.MultiPolygon
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MultiPolygon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.MultiPolygon";
                };
    
                return MultiPolygon;
            })();
    
            v1beta1.Job = (function() {
    
                /**
                 * Properties of a Job.
                 * @memberof ground.v1beta1
                 * @interface IJob
                 * @property {string|null} [id] Job id
                 * @property {number|null} [index] Job index
                 * @property {string|null} [name] Job name
                 * @property {ground.v1beta1.IStyle|null} [style] Job style
                 * @property {Array.<ground.v1beta1.ITask>|null} [tasks] Job tasks
                 */
    
                /**
                 * Constructs a new Job.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Job.
                 * @implements IJob
                 * @constructor
                 * @param {ground.v1beta1.IJob=} [properties] Properties to set
                 */
                function Job(properties) {
                    this.tasks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Job id.
                 * @member {string} id
                 * @memberof ground.v1beta1.Job
                 * @instance
                 */
                Job.prototype.id = "";
    
                /**
                 * Job index.
                 * @member {number} index
                 * @memberof ground.v1beta1.Job
                 * @instance
                 */
                Job.prototype.index = 0;
    
                /**
                 * Job name.
                 * @member {string} name
                 * @memberof ground.v1beta1.Job
                 * @instance
                 */
                Job.prototype.name = "";
    
                /**
                 * Job style.
                 * @member {ground.v1beta1.IStyle|null|undefined} style
                 * @memberof ground.v1beta1.Job
                 * @instance
                 */
                Job.prototype.style = null;
    
                /**
                 * Job tasks.
                 * @member {Array.<ground.v1beta1.ITask>} tasks
                 * @memberof ground.v1beta1.Job
                 * @instance
                 */
                Job.prototype.tasks = $util.emptyArray;
    
                /**
                 * Creates a new Job instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {ground.v1beta1.IJob=} [properties] Properties to set
                 * @returns {ground.v1beta1.Job} Job instance
                 */
                Job.create = function create(properties) {
                    return new Job(properties);
                };
    
                /**
                 * Encodes the specified Job message. Does not implicitly {@link ground.v1beta1.Job.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {ground.v1beta1.IJob} message Job message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Job.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                        $root.ground.v1beta1.Style.encode(message.style, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.tasks != null && message.tasks.length)
                        for (var i = 0; i < message.tasks.length; ++i)
                            $root.ground.v1beta1.Task.encode(message.tasks[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Job message, length delimited. Does not implicitly {@link ground.v1beta1.Job.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {ground.v1beta1.IJob} message Job message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Job.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Job message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Job} Job
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Job.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Job();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.index = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.style = $root.ground.v1beta1.Style.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.ground.v1beta1.Task.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Job message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Job} Job
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Job.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Job message.
                 * @function verify
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Job.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.index != null && message.hasOwnProperty("index"))
                        if (!$util.isInteger(message.index))
                            return "index: integer expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.style != null && message.hasOwnProperty("style")) {
                        var error = $root.ground.v1beta1.Style.verify(message.style);
                        if (error)
                            return "style." + error;
                    }
                    if (message.tasks != null && message.hasOwnProperty("tasks")) {
                        if (!Array.isArray(message.tasks))
                            return "tasks: array expected";
                        for (var i = 0; i < message.tasks.length; ++i) {
                            var error = $root.ground.v1beta1.Task.verify(message.tasks[i]);
                            if (error)
                                return "tasks." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Job message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Job} Job
                 */
                Job.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Job)
                        return object;
                    var message = new $root.ground.v1beta1.Job();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.index != null)
                        message.index = object.index | 0;
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.style != null) {
                        if (typeof object.style !== "object")
                            throw TypeError(".ground.v1beta1.Job.style: object expected");
                        message.style = $root.ground.v1beta1.Style.fromObject(object.style);
                    }
                    if (object.tasks) {
                        if (!Array.isArray(object.tasks))
                            throw TypeError(".ground.v1beta1.Job.tasks: array expected");
                        message.tasks = [];
                        for (var i = 0; i < object.tasks.length; ++i) {
                            if (typeof object.tasks[i] !== "object")
                                throw TypeError(".ground.v1beta1.Job.tasks: object expected");
                            message.tasks[i] = $root.ground.v1beta1.Task.fromObject(object.tasks[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Job message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {ground.v1beta1.Job} message Job
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Job.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.tasks = [];
                    if (options.defaults) {
                        object.id = "";
                        object.index = 0;
                        object.name = "";
                        object.style = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.index != null && message.hasOwnProperty("index"))
                        object.index = message.index;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.style != null && message.hasOwnProperty("style"))
                        object.style = $root.ground.v1beta1.Style.toObject(message.style, options);
                    if (message.tasks && message.tasks.length) {
                        object.tasks = [];
                        for (var j = 0; j < message.tasks.length; ++j)
                            object.tasks[j] = $root.ground.v1beta1.Task.toObject(message.tasks[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Job to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Job
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Job.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Job
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Job
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Job.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Job";
                };
    
                return Job;
            })();
    
            v1beta1.Style = (function() {
    
                /**
                 * Properties of a Style.
                 * @memberof ground.v1beta1
                 * @interface IStyle
                 * @property {string|null} [color] Style color
                 */
    
                /**
                 * Constructs a new Style.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Style.
                 * @implements IStyle
                 * @constructor
                 * @param {ground.v1beta1.IStyle=} [properties] Properties to set
                 */
                function Style(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Style color.
                 * @member {string} color
                 * @memberof ground.v1beta1.Style
                 * @instance
                 */
                Style.prototype.color = "";
    
                /**
                 * Creates a new Style instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {ground.v1beta1.IStyle=} [properties] Properties to set
                 * @returns {ground.v1beta1.Style} Style instance
                 */
                Style.create = function create(properties) {
                    return new Style(properties);
                };
    
                /**
                 * Encodes the specified Style message. Does not implicitly {@link ground.v1beta1.Style.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {ground.v1beta1.IStyle} message Style message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Style.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.color);
                    return writer;
                };
    
                /**
                 * Encodes the specified Style message, length delimited. Does not implicitly {@link ground.v1beta1.Style.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {ground.v1beta1.IStyle} message Style message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Style.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Style message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Style} Style
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Style.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Style();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.color = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Style message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Style} Style
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Style.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Style message.
                 * @function verify
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Style.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.color != null && message.hasOwnProperty("color"))
                        if (!$util.isString(message.color))
                            return "color: string expected";
                    return null;
                };
    
                /**
                 * Creates a Style message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Style} Style
                 */
                Style.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Style)
                        return object;
                    var message = new $root.ground.v1beta1.Style();
                    if (object.color != null)
                        message.color = String(object.color);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Style message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {ground.v1beta1.Style} message Style
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Style.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.color = "";
                    if (message.color != null && message.hasOwnProperty("color"))
                        object.color = message.color;
                    return object;
                };
    
                /**
                 * Converts this Style to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Style
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Style.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Style
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Style
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Style.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Style";
                };
    
                return Style;
            })();
    
            v1beta1.Task = (function() {
    
                /**
                 * Properties of a Task.
                 * @memberof ground.v1beta1
                 * @interface ITask
                 * @property {string|null} [id] Task id
                 * @property {number|null} [index] Task index
                 * @property {string|null} [prompt] Task prompt
                 * @property {boolean|null} [required] Task required
                 * @property {ground.v1beta1.Task.DataCollectionLevel|null} [level] Task level
                 * @property {Array.<ground.v1beta1.Task.ICondition>|null} [conditions] Task conditions
                 * @property {ground.v1beta1.Task.ITextQuestion|null} [textQuestion] Task textQuestion
                 * @property {ground.v1beta1.Task.INumberQuestion|null} [numberQuestion] Task numberQuestion
                 * @property {ground.v1beta1.Task.IDateTimeQuestion|null} [dateTimeQuestion] Task dateTimeQuestion
                 * @property {ground.v1beta1.Task.IMultipleChoiceQuestion|null} [multipleChoiceQuestion] Task multipleChoiceQuestion
                 * @property {ground.v1beta1.Task.IDrawGeometry|null} [drawGeometry] Task drawGeometry
                 * @property {ground.v1beta1.Task.ICaptureLocation|null} [captureLocation] Task captureLocation
                 * @property {ground.v1beta1.Task.ITakePhoto|null} [takePhoto] Task takePhoto
                 */
    
                /**
                 * Constructs a new Task.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Task.
                 * @implements ITask
                 * @constructor
                 * @param {ground.v1beta1.ITask=} [properties] Properties to set
                 */
                function Task(properties) {
                    this.conditions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Task id.
                 * @member {string} id
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.id = "";
    
                /**
                 * Task index.
                 * @member {number} index
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.index = 0;
    
                /**
                 * Task prompt.
                 * @member {string} prompt
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.prompt = "";
    
                /**
                 * Task required.
                 * @member {boolean} required
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.required = false;
    
                /**
                 * Task level.
                 * @member {ground.v1beta1.Task.DataCollectionLevel} level
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.level = 0;
    
                /**
                 * Task conditions.
                 * @member {Array.<ground.v1beta1.Task.ICondition>} conditions
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.conditions = $util.emptyArray;
    
                /**
                 * Task textQuestion.
                 * @member {ground.v1beta1.Task.ITextQuestion|null|undefined} textQuestion
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.textQuestion = null;
    
                /**
                 * Task numberQuestion.
                 * @member {ground.v1beta1.Task.INumberQuestion|null|undefined} numberQuestion
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.numberQuestion = null;
    
                /**
                 * Task dateTimeQuestion.
                 * @member {ground.v1beta1.Task.IDateTimeQuestion|null|undefined} dateTimeQuestion
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.dateTimeQuestion = null;
    
                /**
                 * Task multipleChoiceQuestion.
                 * @member {ground.v1beta1.Task.IMultipleChoiceQuestion|null|undefined} multipleChoiceQuestion
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.multipleChoiceQuestion = null;
    
                /**
                 * Task drawGeometry.
                 * @member {ground.v1beta1.Task.IDrawGeometry|null|undefined} drawGeometry
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.drawGeometry = null;
    
                /**
                 * Task captureLocation.
                 * @member {ground.v1beta1.Task.ICaptureLocation|null|undefined} captureLocation
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.captureLocation = null;
    
                /**
                 * Task takePhoto.
                 * @member {ground.v1beta1.Task.ITakePhoto|null|undefined} takePhoto
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Task.prototype.takePhoto = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * Task taskType.
                 * @member {"textQuestion"|"numberQuestion"|"dateTimeQuestion"|"multipleChoiceQuestion"|"drawGeometry"|"captureLocation"|"takePhoto"|undefined} taskType
                 * @memberof ground.v1beta1.Task
                 * @instance
                 */
                Object.defineProperty(Task.prototype, "taskType", {
                    get: $util.oneOfGetter($oneOfFields = ["textQuestion", "numberQuestion", "dateTimeQuestion", "multipleChoiceQuestion", "drawGeometry", "captureLocation", "takePhoto"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new Task instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {ground.v1beta1.ITask=} [properties] Properties to set
                 * @returns {ground.v1beta1.Task} Task instance
                 */
                Task.create = function create(properties) {
                    return new Task(properties);
                };
    
                /**
                 * Encodes the specified Task message. Does not implicitly {@link ground.v1beta1.Task.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {ground.v1beta1.ITask} message Task message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Task.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
                    if (message.prompt != null && Object.hasOwnProperty.call(message, "prompt"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.prompt);
                    if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.required);
                    if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.level);
                    if (message.conditions != null && message.conditions.length)
                        for (var i = 0; i < message.conditions.length; ++i)
                            $root.ground.v1beta1.Task.Condition.encode(message.conditions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.textQuestion != null && Object.hasOwnProperty.call(message, "textQuestion"))
                        $root.ground.v1beta1.Task.TextQuestion.encode(message.textQuestion, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.numberQuestion != null && Object.hasOwnProperty.call(message, "numberQuestion"))
                        $root.ground.v1beta1.Task.NumberQuestion.encode(message.numberQuestion, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.dateTimeQuestion != null && Object.hasOwnProperty.call(message, "dateTimeQuestion"))
                        $root.ground.v1beta1.Task.DateTimeQuestion.encode(message.dateTimeQuestion, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.multipleChoiceQuestion != null && Object.hasOwnProperty.call(message, "multipleChoiceQuestion"))
                        $root.ground.v1beta1.Task.MultipleChoiceQuestion.encode(message.multipleChoiceQuestion, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.drawGeometry != null && Object.hasOwnProperty.call(message, "drawGeometry"))
                        $root.ground.v1beta1.Task.DrawGeometry.encode(message.drawGeometry, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.captureLocation != null && Object.hasOwnProperty.call(message, "captureLocation"))
                        $root.ground.v1beta1.Task.CaptureLocation.encode(message.captureLocation, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.takePhoto != null && Object.hasOwnProperty.call(message, "takePhoto"))
                        $root.ground.v1beta1.Task.TakePhoto.encode(message.takePhoto, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Task message, length delimited. Does not implicitly {@link ground.v1beta1.Task.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {ground.v1beta1.ITask} message Task message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Task.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Task message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Task} Task
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Task.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.index = reader.int32();
                                break;
                            }
                        case 3: {
                                message.prompt = reader.string();
                                break;
                            }
                        case 4: {
                                message.required = reader.bool();
                                break;
                            }
                        case 5: {
                                message.level = reader.int32();
                                break;
                            }
                        case 6: {
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                message.conditions.push($root.ground.v1beta1.Task.Condition.decode(reader, reader.uint32()));
                                break;
                            }
                        case 7: {
                                message.textQuestion = $root.ground.v1beta1.Task.TextQuestion.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.numberQuestion = $root.ground.v1beta1.Task.NumberQuestion.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.dateTimeQuestion = $root.ground.v1beta1.Task.DateTimeQuestion.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.multipleChoiceQuestion = $root.ground.v1beta1.Task.MultipleChoiceQuestion.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.drawGeometry = $root.ground.v1beta1.Task.DrawGeometry.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.captureLocation = $root.ground.v1beta1.Task.CaptureLocation.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.takePhoto = $root.ground.v1beta1.Task.TakePhoto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Task message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Task} Task
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Task.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Task message.
                 * @function verify
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Task.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.index != null && message.hasOwnProperty("index"))
                        if (!$util.isInteger(message.index))
                            return "index: integer expected";
                    if (message.prompt != null && message.hasOwnProperty("prompt"))
                        if (!$util.isString(message.prompt))
                            return "prompt: string expected";
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        switch (message.level) {
                        default:
                            return "level: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.conditions != null && message.hasOwnProperty("conditions")) {
                        if (!Array.isArray(message.conditions))
                            return "conditions: array expected";
                        for (var i = 0; i < message.conditions.length; ++i) {
                            var error = $root.ground.v1beta1.Task.Condition.verify(message.conditions[i]);
                            if (error)
                                return "conditions." + error;
                        }
                    }
                    if (message.textQuestion != null && message.hasOwnProperty("textQuestion")) {
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.TextQuestion.verify(message.textQuestion);
                            if (error)
                                return "textQuestion." + error;
                        }
                    }
                    if (message.numberQuestion != null && message.hasOwnProperty("numberQuestion")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.NumberQuestion.verify(message.numberQuestion);
                            if (error)
                                return "numberQuestion." + error;
                        }
                    }
                    if (message.dateTimeQuestion != null && message.hasOwnProperty("dateTimeQuestion")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.DateTimeQuestion.verify(message.dateTimeQuestion);
                            if (error)
                                return "dateTimeQuestion." + error;
                        }
                    }
                    if (message.multipleChoiceQuestion != null && message.hasOwnProperty("multipleChoiceQuestion")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.MultipleChoiceQuestion.verify(message.multipleChoiceQuestion);
                            if (error)
                                return "multipleChoiceQuestion." + error;
                        }
                    }
                    if (message.drawGeometry != null && message.hasOwnProperty("drawGeometry")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.DrawGeometry.verify(message.drawGeometry);
                            if (error)
                                return "drawGeometry." + error;
                        }
                    }
                    if (message.captureLocation != null && message.hasOwnProperty("captureLocation")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.CaptureLocation.verify(message.captureLocation);
                            if (error)
                                return "captureLocation." + error;
                        }
                    }
                    if (message.takePhoto != null && message.hasOwnProperty("takePhoto")) {
                        if (properties.taskType === 1)
                            return "taskType: multiple values";
                        properties.taskType = 1;
                        {
                            var error = $root.ground.v1beta1.Task.TakePhoto.verify(message.takePhoto);
                            if (error)
                                return "takePhoto." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Task message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Task} Task
                 */
                Task.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Task)
                        return object;
                    var message = new $root.ground.v1beta1.Task();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.index != null)
                        message.index = object.index | 0;
                    if (object.prompt != null)
                        message.prompt = String(object.prompt);
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    switch (object.level) {
                    default:
                        if (typeof object.level === "number") {
                            message.level = object.level;
                            break;
                        }
                        break;
                    case "DATA_COLLECTION_LEVEL_UNSPECIFIED":
                    case 0:
                        message.level = 0;
                        break;
                    case "LOI_METADATA":
                    case 1:
                        message.level = 1;
                        break;
                    case "LOI_DATA":
                    case 2:
                        message.level = 2;
                        break;
                    }
                    if (object.conditions) {
                        if (!Array.isArray(object.conditions))
                            throw TypeError(".ground.v1beta1.Task.conditions: array expected");
                        message.conditions = [];
                        for (var i = 0; i < object.conditions.length; ++i) {
                            if (typeof object.conditions[i] !== "object")
                                throw TypeError(".ground.v1beta1.Task.conditions: object expected");
                            message.conditions[i] = $root.ground.v1beta1.Task.Condition.fromObject(object.conditions[i]);
                        }
                    }
                    if (object.textQuestion != null) {
                        if (typeof object.textQuestion !== "object")
                            throw TypeError(".ground.v1beta1.Task.textQuestion: object expected");
                        message.textQuestion = $root.ground.v1beta1.Task.TextQuestion.fromObject(object.textQuestion);
                    }
                    if (object.numberQuestion != null) {
                        if (typeof object.numberQuestion !== "object")
                            throw TypeError(".ground.v1beta1.Task.numberQuestion: object expected");
                        message.numberQuestion = $root.ground.v1beta1.Task.NumberQuestion.fromObject(object.numberQuestion);
                    }
                    if (object.dateTimeQuestion != null) {
                        if (typeof object.dateTimeQuestion !== "object")
                            throw TypeError(".ground.v1beta1.Task.dateTimeQuestion: object expected");
                        message.dateTimeQuestion = $root.ground.v1beta1.Task.DateTimeQuestion.fromObject(object.dateTimeQuestion);
                    }
                    if (object.multipleChoiceQuestion != null) {
                        if (typeof object.multipleChoiceQuestion !== "object")
                            throw TypeError(".ground.v1beta1.Task.multipleChoiceQuestion: object expected");
                        message.multipleChoiceQuestion = $root.ground.v1beta1.Task.MultipleChoiceQuestion.fromObject(object.multipleChoiceQuestion);
                    }
                    if (object.drawGeometry != null) {
                        if (typeof object.drawGeometry !== "object")
                            throw TypeError(".ground.v1beta1.Task.drawGeometry: object expected");
                        message.drawGeometry = $root.ground.v1beta1.Task.DrawGeometry.fromObject(object.drawGeometry);
                    }
                    if (object.captureLocation != null) {
                        if (typeof object.captureLocation !== "object")
                            throw TypeError(".ground.v1beta1.Task.captureLocation: object expected");
                        message.captureLocation = $root.ground.v1beta1.Task.CaptureLocation.fromObject(object.captureLocation);
                    }
                    if (object.takePhoto != null) {
                        if (typeof object.takePhoto !== "object")
                            throw TypeError(".ground.v1beta1.Task.takePhoto: object expected");
                        message.takePhoto = $root.ground.v1beta1.Task.TakePhoto.fromObject(object.takePhoto);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Task message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {ground.v1beta1.Task} message Task
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Task.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.conditions = [];
                    if (options.defaults) {
                        object.id = "";
                        object.index = 0;
                        object.prompt = "";
                        object.required = false;
                        object.level = options.enums === String ? "DATA_COLLECTION_LEVEL_UNSPECIFIED" : 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.index != null && message.hasOwnProperty("index"))
                        object.index = message.index;
                    if (message.prompt != null && message.hasOwnProperty("prompt"))
                        object.prompt = message.prompt;
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    if (message.level != null && message.hasOwnProperty("level"))
                        object.level = options.enums === String ? $root.ground.v1beta1.Task.DataCollectionLevel[message.level] === undefined ? message.level : $root.ground.v1beta1.Task.DataCollectionLevel[message.level] : message.level;
                    if (message.conditions && message.conditions.length) {
                        object.conditions = [];
                        for (var j = 0; j < message.conditions.length; ++j)
                            object.conditions[j] = $root.ground.v1beta1.Task.Condition.toObject(message.conditions[j], options);
                    }
                    if (message.textQuestion != null && message.hasOwnProperty("textQuestion")) {
                        object.textQuestion = $root.ground.v1beta1.Task.TextQuestion.toObject(message.textQuestion, options);
                        if (options.oneofs)
                            object.taskType = "textQuestion";
                    }
                    if (message.numberQuestion != null && message.hasOwnProperty("numberQuestion")) {
                        object.numberQuestion = $root.ground.v1beta1.Task.NumberQuestion.toObject(message.numberQuestion, options);
                        if (options.oneofs)
                            object.taskType = "numberQuestion";
                    }
                    if (message.dateTimeQuestion != null && message.hasOwnProperty("dateTimeQuestion")) {
                        object.dateTimeQuestion = $root.ground.v1beta1.Task.DateTimeQuestion.toObject(message.dateTimeQuestion, options);
                        if (options.oneofs)
                            object.taskType = "dateTimeQuestion";
                    }
                    if (message.multipleChoiceQuestion != null && message.hasOwnProperty("multipleChoiceQuestion")) {
                        object.multipleChoiceQuestion = $root.ground.v1beta1.Task.MultipleChoiceQuestion.toObject(message.multipleChoiceQuestion, options);
                        if (options.oneofs)
                            object.taskType = "multipleChoiceQuestion";
                    }
                    if (message.drawGeometry != null && message.hasOwnProperty("drawGeometry")) {
                        object.drawGeometry = $root.ground.v1beta1.Task.DrawGeometry.toObject(message.drawGeometry, options);
                        if (options.oneofs)
                            object.taskType = "drawGeometry";
                    }
                    if (message.captureLocation != null && message.hasOwnProperty("captureLocation")) {
                        object.captureLocation = $root.ground.v1beta1.Task.CaptureLocation.toObject(message.captureLocation, options);
                        if (options.oneofs)
                            object.taskType = "captureLocation";
                    }
                    if (message.takePhoto != null && message.hasOwnProperty("takePhoto")) {
                        object.takePhoto = $root.ground.v1beta1.Task.TakePhoto.toObject(message.takePhoto, options);
                        if (options.oneofs)
                            object.taskType = "takePhoto";
                    }
                    return object;
                };
    
                /**
                 * Converts this Task to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Task
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Task.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Task
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Task
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Task";
                };
    
                /**
                 * DataCollectionLevel enum.
                 * @name ground.v1beta1.Task.DataCollectionLevel
                 * @enum {number}
                 * @property {number} DATA_COLLECTION_LEVEL_UNSPECIFIED=0 DATA_COLLECTION_LEVEL_UNSPECIFIED value
                 * @property {number} LOI_METADATA=1 LOI_METADATA value
                 * @property {number} LOI_DATA=2 LOI_DATA value
                 */
                Task.DataCollectionLevel = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DATA_COLLECTION_LEVEL_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "LOI_METADATA"] = 1;
                    values[valuesById[2] = "LOI_DATA"] = 2;
                    return values;
                })();
    
                Task.TextQuestion = (function() {
    
                    /**
                     * Properties of a TextQuestion.
                     * @memberof ground.v1beta1.Task
                     * @interface ITextQuestion
                     * @property {ground.v1beta1.Task.TextQuestion.Type|null} [type] TextQuestion type
                     */
    
                    /**
                     * Constructs a new TextQuestion.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a TextQuestion.
                     * @implements ITextQuestion
                     * @constructor
                     * @param {ground.v1beta1.Task.ITextQuestion=} [properties] Properties to set
                     */
                    function TextQuestion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TextQuestion type.
                     * @member {ground.v1beta1.Task.TextQuestion.Type} type
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @instance
                     */
                    TextQuestion.prototype.type = 0;
    
                    /**
                     * Creates a new TextQuestion instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {ground.v1beta1.Task.ITextQuestion=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.TextQuestion} TextQuestion instance
                     */
                    TextQuestion.create = function create(properties) {
                        return new TextQuestion(properties);
                    };
    
                    /**
                     * Encodes the specified TextQuestion message. Does not implicitly {@link ground.v1beta1.Task.TextQuestion.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {ground.v1beta1.Task.ITextQuestion} message TextQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextQuestion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TextQuestion message, length delimited. Does not implicitly {@link ground.v1beta1.Task.TextQuestion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {ground.v1beta1.Task.ITextQuestion} message TextQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextQuestion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TextQuestion message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.TextQuestion} TextQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextQuestion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.TextQuestion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.type = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TextQuestion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.TextQuestion} TextQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextQuestion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TextQuestion message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TextQuestion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a TextQuestion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.TextQuestion} TextQuestion
                     */
                    TextQuestion.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.TextQuestion)
                            return object;
                        var message = new $root.ground.v1beta1.Task.TextQuestion();
                        switch (object.type) {
                        default:
                            if (typeof object.type === "number") {
                                message.type = object.type;
                                break;
                            }
                            break;
                        case "TYPE_UNSPECIFIED":
                        case 0:
                            message.type = 0;
                            break;
                        case "SHORT_TEXT":
                        case 1:
                            message.type = 1;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TextQuestion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {ground.v1beta1.Task.TextQuestion} message TextQuestion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TextQuestion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.ground.v1beta1.Task.TextQuestion.Type[message.type] === undefined ? message.type : $root.ground.v1beta1.Task.TextQuestion.Type[message.type] : message.type;
                        return object;
                    };
    
                    /**
                     * Converts this TextQuestion to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TextQuestion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TextQuestion
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.TextQuestion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TextQuestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.TextQuestion";
                    };
    
                    /**
                     * Type enum.
                     * @name ground.v1beta1.Task.TextQuestion.Type
                     * @enum {number}
                     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
                     * @property {number} SHORT_TEXT=1 SHORT_TEXT value
                     */
                    TextQuestion.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "SHORT_TEXT"] = 1;
                        return values;
                    })();
    
                    return TextQuestion;
                })();
    
                Task.NumberQuestion = (function() {
    
                    /**
                     * Properties of a NumberQuestion.
                     * @memberof ground.v1beta1.Task
                     * @interface INumberQuestion
                     * @property {ground.v1beta1.Task.NumberQuestion.Type|null} [type] NumberQuestion type
                     */
    
                    /**
                     * Constructs a new NumberQuestion.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a NumberQuestion.
                     * @implements INumberQuestion
                     * @constructor
                     * @param {ground.v1beta1.Task.INumberQuestion=} [properties] Properties to set
                     */
                    function NumberQuestion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NumberQuestion type.
                     * @member {ground.v1beta1.Task.NumberQuestion.Type} type
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @instance
                     */
                    NumberQuestion.prototype.type = 0;
    
                    /**
                     * Creates a new NumberQuestion instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {ground.v1beta1.Task.INumberQuestion=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.NumberQuestion} NumberQuestion instance
                     */
                    NumberQuestion.create = function create(properties) {
                        return new NumberQuestion(properties);
                    };
    
                    /**
                     * Encodes the specified NumberQuestion message. Does not implicitly {@link ground.v1beta1.Task.NumberQuestion.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {ground.v1beta1.Task.INumberQuestion} message NumberQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NumberQuestion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NumberQuestion message, length delimited. Does not implicitly {@link ground.v1beta1.Task.NumberQuestion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {ground.v1beta1.Task.INumberQuestion} message NumberQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NumberQuestion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NumberQuestion message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.NumberQuestion} NumberQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NumberQuestion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.NumberQuestion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.type = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a NumberQuestion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.NumberQuestion} NumberQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NumberQuestion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NumberQuestion message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NumberQuestion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a NumberQuestion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.NumberQuestion} NumberQuestion
                     */
                    NumberQuestion.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.NumberQuestion)
                            return object;
                        var message = new $root.ground.v1beta1.Task.NumberQuestion();
                        switch (object.type) {
                        default:
                            if (typeof object.type === "number") {
                                message.type = object.type;
                                break;
                            }
                            break;
                        case "TYPE_UNSPECIFIED":
                        case 0:
                            message.type = 0;
                            break;
                        case "FLOAT":
                        case 1:
                            message.type = 1;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NumberQuestion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {ground.v1beta1.Task.NumberQuestion} message NumberQuestion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NumberQuestion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.ground.v1beta1.Task.NumberQuestion.Type[message.type] === undefined ? message.type : $root.ground.v1beta1.Task.NumberQuestion.Type[message.type] : message.type;
                        return object;
                    };
    
                    /**
                     * Converts this NumberQuestion to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NumberQuestion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for NumberQuestion
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.NumberQuestion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    NumberQuestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.NumberQuestion";
                    };
    
                    /**
                     * Type enum.
                     * @name ground.v1beta1.Task.NumberQuestion.Type
                     * @enum {number}
                     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
                     * @property {number} FLOAT=1 FLOAT value
                     */
                    NumberQuestion.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "FLOAT"] = 1;
                        return values;
                    })();
    
                    return NumberQuestion;
                })();
    
                Task.DateTimeQuestion = (function() {
    
                    /**
                     * Properties of a DateTimeQuestion.
                     * @memberof ground.v1beta1.Task
                     * @interface IDateTimeQuestion
                     * @property {ground.v1beta1.Task.DateTimeQuestion.Type|null} [type] DateTimeQuestion type
                     */
    
                    /**
                     * Constructs a new DateTimeQuestion.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a DateTimeQuestion.
                     * @implements IDateTimeQuestion
                     * @constructor
                     * @param {ground.v1beta1.Task.IDateTimeQuestion=} [properties] Properties to set
                     */
                    function DateTimeQuestion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DateTimeQuestion type.
                     * @member {ground.v1beta1.Task.DateTimeQuestion.Type} type
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @instance
                     */
                    DateTimeQuestion.prototype.type = 0;
    
                    /**
                     * Creates a new DateTimeQuestion instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IDateTimeQuestion=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.DateTimeQuestion} DateTimeQuestion instance
                     */
                    DateTimeQuestion.create = function create(properties) {
                        return new DateTimeQuestion(properties);
                    };
    
                    /**
                     * Encodes the specified DateTimeQuestion message. Does not implicitly {@link ground.v1beta1.Task.DateTimeQuestion.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IDateTimeQuestion} message DateTimeQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateTimeQuestion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DateTimeQuestion message, length delimited. Does not implicitly {@link ground.v1beta1.Task.DateTimeQuestion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IDateTimeQuestion} message DateTimeQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateTimeQuestion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DateTimeQuestion message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.DateTimeQuestion} DateTimeQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateTimeQuestion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.DateTimeQuestion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.type = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DateTimeQuestion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.DateTimeQuestion} DateTimeQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateTimeQuestion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DateTimeQuestion message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DateTimeQuestion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };
    
                    /**
                     * Creates a DateTimeQuestion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.DateTimeQuestion} DateTimeQuestion
                     */
                    DateTimeQuestion.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.DateTimeQuestion)
                            return object;
                        var message = new $root.ground.v1beta1.Task.DateTimeQuestion();
                        switch (object.type) {
                        default:
                            if (typeof object.type === "number") {
                                message.type = object.type;
                                break;
                            }
                            break;
                        case "TYPE_UNSPECIFIED":
                        case 0:
                            message.type = 0;
                            break;
                        case "DATE_ONLY":
                        case 1:
                            message.type = 1;
                            break;
                        case "TIME_ONLY":
                        case 2:
                            message.type = 2;
                            break;
                        case "BOTH_DATE_AND_TIME":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DateTimeQuestion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {ground.v1beta1.Task.DateTimeQuestion} message DateTimeQuestion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DateTimeQuestion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.ground.v1beta1.Task.DateTimeQuestion.Type[message.type] === undefined ? message.type : $root.ground.v1beta1.Task.DateTimeQuestion.Type[message.type] : message.type;
                        return object;
                    };
    
                    /**
                     * Converts this DateTimeQuestion to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DateTimeQuestion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for DateTimeQuestion
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.DateTimeQuestion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DateTimeQuestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.DateTimeQuestion";
                    };
    
                    /**
                     * Type enum.
                     * @name ground.v1beta1.Task.DateTimeQuestion.Type
                     * @enum {number}
                     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
                     * @property {number} DATE_ONLY=1 DATE_ONLY value
                     * @property {number} TIME_ONLY=2 TIME_ONLY value
                     * @property {number} BOTH_DATE_AND_TIME=3 BOTH_DATE_AND_TIME value
                     */
                    DateTimeQuestion.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "DATE_ONLY"] = 1;
                        values[valuesById[2] = "TIME_ONLY"] = 2;
                        values[valuesById[3] = "BOTH_DATE_AND_TIME"] = 3;
                        return values;
                    })();
    
                    return DateTimeQuestion;
                })();
    
                Task.MultipleChoiceQuestion = (function() {
    
                    /**
                     * Properties of a MultipleChoiceQuestion.
                     * @memberof ground.v1beta1.Task
                     * @interface IMultipleChoiceQuestion
                     * @property {ground.v1beta1.Task.MultipleChoiceQuestion.Type|null} [type] MultipleChoiceQuestion type
                     * @property {Array.<ground.v1beta1.Task.MultipleChoiceQuestion.IOption>|null} [options] MultipleChoiceQuestion options
                     * @property {boolean|null} [hasOtherOption] MultipleChoiceQuestion hasOtherOption
                     */
    
                    /**
                     * Constructs a new MultipleChoiceQuestion.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a MultipleChoiceQuestion.
                     * @implements IMultipleChoiceQuestion
                     * @constructor
                     * @param {ground.v1beta1.Task.IMultipleChoiceQuestion=} [properties] Properties to set
                     */
                    function MultipleChoiceQuestion(properties) {
                        this.options = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MultipleChoiceQuestion type.
                     * @member {ground.v1beta1.Task.MultipleChoiceQuestion.Type} type
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @instance
                     */
                    MultipleChoiceQuestion.prototype.type = 0;
    
                    /**
                     * MultipleChoiceQuestion options.
                     * @member {Array.<ground.v1beta1.Task.MultipleChoiceQuestion.IOption>} options
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @instance
                     */
                    MultipleChoiceQuestion.prototype.options = $util.emptyArray;
    
                    /**
                     * MultipleChoiceQuestion hasOtherOption.
                     * @member {boolean} hasOtherOption
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @instance
                     */
                    MultipleChoiceQuestion.prototype.hasOtherOption = false;
    
                    /**
                     * Creates a new MultipleChoiceQuestion instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceQuestion=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.MultipleChoiceQuestion} MultipleChoiceQuestion instance
                     */
                    MultipleChoiceQuestion.create = function create(properties) {
                        return new MultipleChoiceQuestion(properties);
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceQuestion message. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceQuestion.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceQuestion} message MultipleChoiceQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceQuestion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.options != null && message.options.length)
                            for (var i = 0; i < message.options.length; ++i)
                                $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.hasOtherOption != null && Object.hasOwnProperty.call(message, "hasOtherOption"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasOtherOption);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceQuestion message, length delimited. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceQuestion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceQuestion} message MultipleChoiceQuestion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceQuestion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MultipleChoiceQuestion message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.MultipleChoiceQuestion} MultipleChoiceQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceQuestion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.MultipleChoiceQuestion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.type = reader.int32();
                                    break;
                                }
                            case 2: {
                                    if (!(message.options && message.options.length))
                                        message.options = [];
                                    message.options.push($root.ground.v1beta1.Task.MultipleChoiceQuestion.Option.decode(reader, reader.uint32()));
                                    break;
                                }
                            case 3: {
                                    message.hasOtherOption = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MultipleChoiceQuestion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.MultipleChoiceQuestion} MultipleChoiceQuestion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceQuestion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MultipleChoiceQuestion message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MultipleChoiceQuestion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.options != null && message.hasOwnProperty("options")) {
                            if (!Array.isArray(message.options))
                                return "options: array expected";
                            for (var i = 0; i < message.options.length; ++i) {
                                var error = $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option.verify(message.options[i]);
                                if (error)
                                    return "options." + error;
                            }
                        }
                        if (message.hasOtherOption != null && message.hasOwnProperty("hasOtherOption"))
                            if (typeof message.hasOtherOption !== "boolean")
                                return "hasOtherOption: boolean expected";
                        return null;
                    };
    
                    /**
                     * Creates a MultipleChoiceQuestion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.MultipleChoiceQuestion} MultipleChoiceQuestion
                     */
                    MultipleChoiceQuestion.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.MultipleChoiceQuestion)
                            return object;
                        var message = new $root.ground.v1beta1.Task.MultipleChoiceQuestion();
                        switch (object.type) {
                        default:
                            if (typeof object.type === "number") {
                                message.type = object.type;
                                break;
                            }
                            break;
                        case "TYPE_UNSPECIFIED":
                        case 0:
                            message.type = 0;
                            break;
                        case "SELECT_ONE":
                        case 1:
                            message.type = 1;
                            break;
                        case "SELECT_MULTIPLE":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.options) {
                            if (!Array.isArray(object.options))
                                throw TypeError(".ground.v1beta1.Task.MultipleChoiceQuestion.options: array expected");
                            message.options = [];
                            for (var i = 0; i < object.options.length; ++i) {
                                if (typeof object.options[i] !== "object")
                                    throw TypeError(".ground.v1beta1.Task.MultipleChoiceQuestion.options: object expected");
                                message.options[i] = $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option.fromObject(object.options[i]);
                            }
                        }
                        if (object.hasOtherOption != null)
                            message.hasOtherOption = Boolean(object.hasOtherOption);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MultipleChoiceQuestion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {ground.v1beta1.Task.MultipleChoiceQuestion} message MultipleChoiceQuestion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MultipleChoiceQuestion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.options = [];
                        if (options.defaults) {
                            object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                            object.hasOtherOption = false;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.ground.v1beta1.Task.MultipleChoiceQuestion.Type[message.type] === undefined ? message.type : $root.ground.v1beta1.Task.MultipleChoiceQuestion.Type[message.type] : message.type;
                        if (message.options && message.options.length) {
                            object.options = [];
                            for (var j = 0; j < message.options.length; ++j)
                                object.options[j] = $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option.toObject(message.options[j], options);
                        }
                        if (message.hasOtherOption != null && message.hasOwnProperty("hasOtherOption"))
                            object.hasOtherOption = message.hasOtherOption;
                        return object;
                    };
    
                    /**
                     * Converts this MultipleChoiceQuestion to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MultipleChoiceQuestion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for MultipleChoiceQuestion
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    MultipleChoiceQuestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.MultipleChoiceQuestion";
                    };
    
                    /**
                     * Type enum.
                     * @name ground.v1beta1.Task.MultipleChoiceQuestion.Type
                     * @enum {number}
                     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
                     * @property {number} SELECT_ONE=1 SELECT_ONE value
                     * @property {number} SELECT_MULTIPLE=2 SELECT_MULTIPLE value
                     */
                    MultipleChoiceQuestion.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "SELECT_ONE"] = 1;
                        values[valuesById[2] = "SELECT_MULTIPLE"] = 2;
                        return values;
                    })();
    
                    MultipleChoiceQuestion.Option = (function() {
    
                        /**
                         * Properties of an Option.
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                         * @interface IOption
                         * @property {string|null} [id] Option id
                         * @property {number|null} [index] Option index
                         * @property {string|null} [label] Option label
                         */
    
                        /**
                         * Constructs a new Option.
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion
                         * @classdesc Represents an Option.
                         * @implements IOption
                         * @constructor
                         * @param {ground.v1beta1.Task.MultipleChoiceQuestion.IOption=} [properties] Properties to set
                         */
                        function Option(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }
    
                        /**
                         * Option id.
                         * @member {string} id
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @instance
                         */
                        Option.prototype.id = "";
    
                        /**
                         * Option index.
                         * @member {number} index
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @instance
                         */
                        Option.prototype.index = 0;
    
                        /**
                         * Option label.
                         * @member {string} label
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @instance
                         */
                        Option.prototype.label = "";
    
                        /**
                         * Creates a new Option instance using the specified properties.
                         * @function create
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {ground.v1beta1.Task.MultipleChoiceQuestion.IOption=} [properties] Properties to set
                         * @returns {ground.v1beta1.Task.MultipleChoiceQuestion.Option} Option instance
                         */
                        Option.create = function create(properties) {
                            return new Option(properties);
                        };
    
                        /**
                         * Encodes the specified Option message. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceQuestion.Option.verify|verify} messages.
                         * @function encode
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {ground.v1beta1.Task.MultipleChoiceQuestion.IOption} message Option message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Option.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
                            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
                            return writer;
                        };
    
                        /**
                         * Encodes the specified Option message, length delimited. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceQuestion.Option.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {ground.v1beta1.Task.MultipleChoiceQuestion.IOption} message Option message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Option.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };
    
                        /**
                         * Decodes an Option message from the specified reader or buffer.
                         * @function decode
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {ground.v1beta1.Task.MultipleChoiceQuestion.Option} Option
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Option.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.id = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.index = reader.int32();
                                        break;
                                    }
                                case 3: {
                                        message.label = reader.string();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };
    
                        /**
                         * Decodes an Option message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {ground.v1beta1.Task.MultipleChoiceQuestion.Option} Option
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Option.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };
    
                        /**
                         * Verifies an Option message.
                         * @function verify
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Option.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.index != null && message.hasOwnProperty("index"))
                                if (!$util.isInteger(message.index))
                                    return "index: integer expected";
                            if (message.label != null && message.hasOwnProperty("label"))
                                if (!$util.isString(message.label))
                                    return "label: string expected";
                            return null;
                        };
    
                        /**
                         * Creates an Option message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {ground.v1beta1.Task.MultipleChoiceQuestion.Option} Option
                         */
                        Option.fromObject = function fromObject(object) {
                            if (object instanceof $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option)
                                return object;
                            var message = new $root.ground.v1beta1.Task.MultipleChoiceQuestion.Option();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.index != null)
                                message.index = object.index | 0;
                            if (object.label != null)
                                message.label = String(object.label);
                            return message;
                        };
    
                        /**
                         * Creates a plain object from an Option message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {ground.v1beta1.Task.MultipleChoiceQuestion.Option} message Option
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Option.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.index = 0;
                                object.label = "";
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.index != null && message.hasOwnProperty("index"))
                                object.index = message.index;
                            if (message.label != null && message.hasOwnProperty("label"))
                                object.label = message.label;
                            return object;
                        };
    
                        /**
                         * Converts this Option to JSON.
                         * @function toJSON
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Option.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };
    
                        /**
                         * Gets the default type url for Option
                         * @function getTypeUrl
                         * @memberof ground.v1beta1.Task.MultipleChoiceQuestion.Option
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/ground.v1beta1.Task.MultipleChoiceQuestion.Option";
                        };
    
                        return Option;
                    })();
    
                    return MultipleChoiceQuestion;
                })();
    
                Task.DrawGeometry = (function() {
    
                    /**
                     * Properties of a DrawGeometry.
                     * @memberof ground.v1beta1.Task
                     * @interface IDrawGeometry
                     * @property {Array.<ground.v1beta1.Task.DrawGeometry.Method>|null} [allowedMethods] DrawGeometry allowedMethods
                     */
    
                    /**
                     * Constructs a new DrawGeometry.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a DrawGeometry.
                     * @implements IDrawGeometry
                     * @constructor
                     * @param {ground.v1beta1.Task.IDrawGeometry=} [properties] Properties to set
                     */
                    function DrawGeometry(properties) {
                        this.allowedMethods = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DrawGeometry allowedMethods.
                     * @member {Array.<ground.v1beta1.Task.DrawGeometry.Method>} allowedMethods
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @instance
                     */
                    DrawGeometry.prototype.allowedMethods = $util.emptyArray;
    
                    /**
                     * Creates a new DrawGeometry instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {ground.v1beta1.Task.IDrawGeometry=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.DrawGeometry} DrawGeometry instance
                     */
                    DrawGeometry.create = function create(properties) {
                        return new DrawGeometry(properties);
                    };
    
                    /**
                     * Encodes the specified DrawGeometry message. Does not implicitly {@link ground.v1beta1.Task.DrawGeometry.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {ground.v1beta1.Task.IDrawGeometry} message DrawGeometry message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DrawGeometry.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.allowedMethods != null && message.allowedMethods.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.allowedMethods.length; ++i)
                                writer.int32(message.allowedMethods[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DrawGeometry message, length delimited. Does not implicitly {@link ground.v1beta1.Task.DrawGeometry.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {ground.v1beta1.Task.IDrawGeometry} message DrawGeometry message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DrawGeometry.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DrawGeometry message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.DrawGeometry} DrawGeometry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DrawGeometry.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.DrawGeometry();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.allowedMethods && message.allowedMethods.length))
                                        message.allowedMethods = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.allowedMethods.push(reader.int32());
                                    } else
                                        message.allowedMethods.push(reader.int32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DrawGeometry message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.DrawGeometry} DrawGeometry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DrawGeometry.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DrawGeometry message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DrawGeometry.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.allowedMethods != null && message.hasOwnProperty("allowedMethods")) {
                            if (!Array.isArray(message.allowedMethods))
                                return "allowedMethods: array expected";
                            for (var i = 0; i < message.allowedMethods.length; ++i)
                                switch (message.allowedMethods[i]) {
                                default:
                                    return "allowedMethods: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                    break;
                                }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DrawGeometry message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.DrawGeometry} DrawGeometry
                     */
                    DrawGeometry.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.DrawGeometry)
                            return object;
                        var message = new $root.ground.v1beta1.Task.DrawGeometry();
                        if (object.allowedMethods) {
                            if (!Array.isArray(object.allowedMethods))
                                throw TypeError(".ground.v1beta1.Task.DrawGeometry.allowedMethods: array expected");
                            message.allowedMethods = [];
                            for (var i = 0; i < object.allowedMethods.length; ++i)
                                switch (object.allowedMethods[i]) {
                                default:
                                    if (typeof object.allowedMethods[i] === "number") {
                                        message.allowedMethods[i] = object.allowedMethods[i];
                                        break;
                                    }
                                case "METHOD_UNSPECIFIED":
                                case 0:
                                    message.allowedMethods[i] = 0;
                                    break;
                                case "DROP_PIN":
                                case 1:
                                    message.allowedMethods[i] = 1;
                                    break;
                                case "DRAW_AREA":
                                case 2:
                                    message.allowedMethods[i] = 2;
                                    break;
                                }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DrawGeometry message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {ground.v1beta1.Task.DrawGeometry} message DrawGeometry
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DrawGeometry.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.allowedMethods = [];
                        if (message.allowedMethods && message.allowedMethods.length) {
                            object.allowedMethods = [];
                            for (var j = 0; j < message.allowedMethods.length; ++j)
                                object.allowedMethods[j] = options.enums === String ? $root.ground.v1beta1.Task.DrawGeometry.Method[message.allowedMethods[j]] === undefined ? message.allowedMethods[j] : $root.ground.v1beta1.Task.DrawGeometry.Method[message.allowedMethods[j]] : message.allowedMethods[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this DrawGeometry to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DrawGeometry.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for DrawGeometry
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.DrawGeometry
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DrawGeometry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.DrawGeometry";
                    };
    
                    /**
                     * Method enum.
                     * @name ground.v1beta1.Task.DrawGeometry.Method
                     * @enum {number}
                     * @property {number} METHOD_UNSPECIFIED=0 METHOD_UNSPECIFIED value
                     * @property {number} DROP_PIN=1 DROP_PIN value
                     * @property {number} DRAW_AREA=2 DRAW_AREA value
                     */
                    DrawGeometry.Method = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "METHOD_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "DROP_PIN"] = 1;
                        values[valuesById[2] = "DRAW_AREA"] = 2;
                        return values;
                    })();
    
                    return DrawGeometry;
                })();
    
                Task.CaptureLocation = (function() {
    
                    /**
                     * Properties of a CaptureLocation.
                     * @memberof ground.v1beta1.Task
                     * @interface ICaptureLocation
                     * @property {number|null} [minAccuracyMeters] CaptureLocation minAccuracyMeters
                     */
    
                    /**
                     * Constructs a new CaptureLocation.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a CaptureLocation.
                     * @implements ICaptureLocation
                     * @constructor
                     * @param {ground.v1beta1.Task.ICaptureLocation=} [properties] Properties to set
                     */
                    function CaptureLocation(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CaptureLocation minAccuracyMeters.
                     * @member {number} minAccuracyMeters
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @instance
                     */
                    CaptureLocation.prototype.minAccuracyMeters = 0;
    
                    /**
                     * Creates a new CaptureLocation instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {ground.v1beta1.Task.ICaptureLocation=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.CaptureLocation} CaptureLocation instance
                     */
                    CaptureLocation.create = function create(properties) {
                        return new CaptureLocation(properties);
                    };
    
                    /**
                     * Encodes the specified CaptureLocation message. Does not implicitly {@link ground.v1beta1.Task.CaptureLocation.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {ground.v1beta1.Task.ICaptureLocation} message CaptureLocation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CaptureLocation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minAccuracyMeters != null && Object.hasOwnProperty.call(message, "minAccuracyMeters"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.minAccuracyMeters);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CaptureLocation message, length delimited. Does not implicitly {@link ground.v1beta1.Task.CaptureLocation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {ground.v1beta1.Task.ICaptureLocation} message CaptureLocation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CaptureLocation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CaptureLocation message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.CaptureLocation} CaptureLocation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CaptureLocation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.CaptureLocation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.minAccuracyMeters = reader.float();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CaptureLocation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.CaptureLocation} CaptureLocation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CaptureLocation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CaptureLocation message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CaptureLocation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minAccuracyMeters != null && message.hasOwnProperty("minAccuracyMeters"))
                            if (typeof message.minAccuracyMeters !== "number")
                                return "minAccuracyMeters: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a CaptureLocation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.CaptureLocation} CaptureLocation
                     */
                    CaptureLocation.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.CaptureLocation)
                            return object;
                        var message = new $root.ground.v1beta1.Task.CaptureLocation();
                        if (object.minAccuracyMeters != null)
                            message.minAccuracyMeters = Number(object.minAccuracyMeters);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CaptureLocation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {ground.v1beta1.Task.CaptureLocation} message CaptureLocation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CaptureLocation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.minAccuracyMeters = 0;
                        if (message.minAccuracyMeters != null && message.hasOwnProperty("minAccuracyMeters"))
                            object.minAccuracyMeters = options.json && !isFinite(message.minAccuracyMeters) ? String(message.minAccuracyMeters) : message.minAccuracyMeters;
                        return object;
                    };
    
                    /**
                     * Converts this CaptureLocation to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CaptureLocation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for CaptureLocation
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.CaptureLocation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CaptureLocation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.CaptureLocation";
                    };
    
                    return CaptureLocation;
                })();
    
                Task.TakePhoto = (function() {
    
                    /**
                     * Properties of a TakePhoto.
                     * @memberof ground.v1beta1.Task
                     * @interface ITakePhoto
                     * @property {number|null} [minHeadingDegrees] TakePhoto minHeadingDegrees
                     * @property {number|null} [maxHeadingDegrees] TakePhoto maxHeadingDegrees
                     */
    
                    /**
                     * Constructs a new TakePhoto.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a TakePhoto.
                     * @implements ITakePhoto
                     * @constructor
                     * @param {ground.v1beta1.Task.ITakePhoto=} [properties] Properties to set
                     */
                    function TakePhoto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TakePhoto minHeadingDegrees.
                     * @member {number} minHeadingDegrees
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @instance
                     */
                    TakePhoto.prototype.minHeadingDegrees = 0;
    
                    /**
                     * TakePhoto maxHeadingDegrees.
                     * @member {number} maxHeadingDegrees
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @instance
                     */
                    TakePhoto.prototype.maxHeadingDegrees = 0;
    
                    /**
                     * Creates a new TakePhoto instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {ground.v1beta1.Task.ITakePhoto=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.TakePhoto} TakePhoto instance
                     */
                    TakePhoto.create = function create(properties) {
                        return new TakePhoto(properties);
                    };
    
                    /**
                     * Encodes the specified TakePhoto message. Does not implicitly {@link ground.v1beta1.Task.TakePhoto.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {ground.v1beta1.Task.ITakePhoto} message TakePhoto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakePhoto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.minHeadingDegrees != null && Object.hasOwnProperty.call(message, "minHeadingDegrees"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.minHeadingDegrees);
                        if (message.maxHeadingDegrees != null && Object.hasOwnProperty.call(message, "maxHeadingDegrees"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.maxHeadingDegrees);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TakePhoto message, length delimited. Does not implicitly {@link ground.v1beta1.Task.TakePhoto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {ground.v1beta1.Task.ITakePhoto} message TakePhoto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakePhoto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TakePhoto message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.TakePhoto} TakePhoto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakePhoto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.TakePhoto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.minHeadingDegrees = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.maxHeadingDegrees = reader.uint32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TakePhoto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.TakePhoto} TakePhoto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakePhoto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TakePhoto message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TakePhoto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.minHeadingDegrees != null && message.hasOwnProperty("minHeadingDegrees"))
                            if (!$util.isInteger(message.minHeadingDegrees))
                                return "minHeadingDegrees: integer expected";
                        if (message.maxHeadingDegrees != null && message.hasOwnProperty("maxHeadingDegrees"))
                            if (!$util.isInteger(message.maxHeadingDegrees))
                                return "maxHeadingDegrees: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a TakePhoto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.TakePhoto} TakePhoto
                     */
                    TakePhoto.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.TakePhoto)
                            return object;
                        var message = new $root.ground.v1beta1.Task.TakePhoto();
                        if (object.minHeadingDegrees != null)
                            message.minHeadingDegrees = object.minHeadingDegrees >>> 0;
                        if (object.maxHeadingDegrees != null)
                            message.maxHeadingDegrees = object.maxHeadingDegrees >>> 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TakePhoto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {ground.v1beta1.Task.TakePhoto} message TakePhoto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TakePhoto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.minHeadingDegrees = 0;
                            object.maxHeadingDegrees = 0;
                        }
                        if (message.minHeadingDegrees != null && message.hasOwnProperty("minHeadingDegrees"))
                            object.minHeadingDegrees = message.minHeadingDegrees;
                        if (message.maxHeadingDegrees != null && message.hasOwnProperty("maxHeadingDegrees"))
                            object.maxHeadingDegrees = message.maxHeadingDegrees;
                        return object;
                    };
    
                    /**
                     * Converts this TakePhoto to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TakePhoto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TakePhoto
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.TakePhoto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TakePhoto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.TakePhoto";
                    };
    
                    return TakePhoto;
                })();
    
                Task.Condition = (function() {
    
                    /**
                     * Properties of a Condition.
                     * @memberof ground.v1beta1.Task
                     * @interface ICondition
                     * @property {ground.v1beta1.Task.IMultipleChoiceSelection|null} [multipleChoice] Condition multipleChoice
                     */
    
                    /**
                     * Constructs a new Condition.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a Condition.
                     * @implements ICondition
                     * @constructor
                     * @param {ground.v1beta1.Task.ICondition=} [properties] Properties to set
                     */
                    function Condition(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Condition multipleChoice.
                     * @member {ground.v1beta1.Task.IMultipleChoiceSelection|null|undefined} multipleChoice
                     * @memberof ground.v1beta1.Task.Condition
                     * @instance
                     */
                    Condition.prototype.multipleChoice = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Condition conditionType.
                     * @member {"multipleChoice"|undefined} conditionType
                     * @memberof ground.v1beta1.Task.Condition
                     * @instance
                     */
                    Object.defineProperty(Condition.prototype, "conditionType", {
                        get: $util.oneOfGetter($oneOfFields = ["multipleChoice"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Condition instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {ground.v1beta1.Task.ICondition=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.Condition} Condition instance
                     */
                    Condition.create = function create(properties) {
                        return new Condition(properties);
                    };
    
                    /**
                     * Encodes the specified Condition message. Does not implicitly {@link ground.v1beta1.Task.Condition.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {ground.v1beta1.Task.ICondition} message Condition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Condition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.multipleChoice != null && Object.hasOwnProperty.call(message, "multipleChoice"))
                            $root.ground.v1beta1.Task.MultipleChoiceSelection.encode(message.multipleChoice, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Condition message, length delimited. Does not implicitly {@link ground.v1beta1.Task.Condition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {ground.v1beta1.Task.ICondition} message Condition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Condition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Condition message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.Condition} Condition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Condition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.Condition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.multipleChoice = $root.ground.v1beta1.Task.MultipleChoiceSelection.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Condition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.Condition} Condition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Condition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Condition message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Condition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.multipleChoice != null && message.hasOwnProperty("multipleChoice")) {
                            properties.conditionType = 1;
                            {
                                var error = $root.ground.v1beta1.Task.MultipleChoiceSelection.verify(message.multipleChoice);
                                if (error)
                                    return "multipleChoice." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Condition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.Condition} Condition
                     */
                    Condition.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.Condition)
                            return object;
                        var message = new $root.ground.v1beta1.Task.Condition();
                        if (object.multipleChoice != null) {
                            if (typeof object.multipleChoice !== "object")
                                throw TypeError(".ground.v1beta1.Task.Condition.multipleChoice: object expected");
                            message.multipleChoice = $root.ground.v1beta1.Task.MultipleChoiceSelection.fromObject(object.multipleChoice);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Condition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {ground.v1beta1.Task.Condition} message Condition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Condition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.multipleChoice != null && message.hasOwnProperty("multipleChoice")) {
                            object.multipleChoice = $root.ground.v1beta1.Task.MultipleChoiceSelection.toObject(message.multipleChoice, options);
                            if (options.oneofs)
                                object.conditionType = "multipleChoice";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Condition to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.Condition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Condition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Condition
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.Condition
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Condition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.Condition";
                    };
    
                    return Condition;
                })();
    
                Task.MultipleChoiceSelection = (function() {
    
                    /**
                     * Properties of a MultipleChoiceSelection.
                     * @memberof ground.v1beta1.Task
                     * @interface IMultipleChoiceSelection
                     * @property {Array.<string>|null} [optionIds] MultipleChoiceSelection optionIds
                     * @property {string|null} [taskId] MultipleChoiceSelection taskId
                     */
    
                    /**
                     * Constructs a new MultipleChoiceSelection.
                     * @memberof ground.v1beta1.Task
                     * @classdesc Represents a MultipleChoiceSelection.
                     * @implements IMultipleChoiceSelection
                     * @constructor
                     * @param {ground.v1beta1.Task.IMultipleChoiceSelection=} [properties] Properties to set
                     */
                    function MultipleChoiceSelection(properties) {
                        this.optionIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MultipleChoiceSelection optionIds.
                     * @member {Array.<string>} optionIds
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @instance
                     */
                    MultipleChoiceSelection.prototype.optionIds = $util.emptyArray;
    
                    /**
                     * MultipleChoiceSelection taskId.
                     * @member {string} taskId
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @instance
                     */
                    MultipleChoiceSelection.prototype.taskId = "";
    
                    /**
                     * Creates a new MultipleChoiceSelection instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceSelection=} [properties] Properties to set
                     * @returns {ground.v1beta1.Task.MultipleChoiceSelection} MultipleChoiceSelection instance
                     */
                    MultipleChoiceSelection.create = function create(properties) {
                        return new MultipleChoiceSelection(properties);
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceSelection message. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceSelection.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceSelection} message MultipleChoiceSelection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceSelection.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.optionIds != null && message.optionIds.length)
                            for (var i = 0; i < message.optionIds.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.optionIds[i]);
                        if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceSelection message, length delimited. Does not implicitly {@link ground.v1beta1.Task.MultipleChoiceSelection.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {ground.v1beta1.Task.IMultipleChoiceSelection} message MultipleChoiceSelection message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceSelection.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MultipleChoiceSelection message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Task.MultipleChoiceSelection} MultipleChoiceSelection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceSelection.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Task.MultipleChoiceSelection();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.optionIds && message.optionIds.length))
                                        message.optionIds = [];
                                    message.optionIds.push(reader.string());
                                    break;
                                }
                            case 2: {
                                    message.taskId = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MultipleChoiceSelection message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Task.MultipleChoiceSelection} MultipleChoiceSelection
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceSelection.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MultipleChoiceSelection message.
                     * @function verify
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MultipleChoiceSelection.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.optionIds != null && message.hasOwnProperty("optionIds")) {
                            if (!Array.isArray(message.optionIds))
                                return "optionIds: array expected";
                            for (var i = 0; i < message.optionIds.length; ++i)
                                if (!$util.isString(message.optionIds[i]))
                                    return "optionIds: string[] expected";
                        }
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            if (!$util.isString(message.taskId))
                                return "taskId: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a MultipleChoiceSelection message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Task.MultipleChoiceSelection} MultipleChoiceSelection
                     */
                    MultipleChoiceSelection.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Task.MultipleChoiceSelection)
                            return object;
                        var message = new $root.ground.v1beta1.Task.MultipleChoiceSelection();
                        if (object.optionIds) {
                            if (!Array.isArray(object.optionIds))
                                throw TypeError(".ground.v1beta1.Task.MultipleChoiceSelection.optionIds: array expected");
                            message.optionIds = [];
                            for (var i = 0; i < object.optionIds.length; ++i)
                                message.optionIds[i] = String(object.optionIds[i]);
                        }
                        if (object.taskId != null)
                            message.taskId = String(object.taskId);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MultipleChoiceSelection message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {ground.v1beta1.Task.MultipleChoiceSelection} message MultipleChoiceSelection
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MultipleChoiceSelection.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.optionIds = [];
                        if (options.defaults)
                            object.taskId = "";
                        if (message.optionIds && message.optionIds.length) {
                            object.optionIds = [];
                            for (var j = 0; j < message.optionIds.length; ++j)
                                object.optionIds[j] = message.optionIds[j];
                        }
                        if (message.taskId != null && message.hasOwnProperty("taskId"))
                            object.taskId = message.taskId;
                        return object;
                    };
    
                    /**
                     * Converts this MultipleChoiceSelection to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MultipleChoiceSelection.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for MultipleChoiceSelection
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Task.MultipleChoiceSelection
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    MultipleChoiceSelection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Task.MultipleChoiceSelection";
                    };
    
                    return MultipleChoiceSelection;
                })();
    
                return Task;
            })();
    
            v1beta1.LocationOfInterest = (function() {
    
                /**
                 * Properties of a LocationOfInterest.
                 * @memberof ground.v1beta1
                 * @interface ILocationOfInterest
                 * @property {string|null} [id] LocationOfInterest id
                 * @property {string|null} [jobId] LocationOfInterest jobId
                 * @property {ground.v1beta1.IGeometry|null} [geometry] LocationOfInterest geometry
                 * @property {number|null} [submissionCount] LocationOfInterest submissionCount
                 * @property {string|null} [ownerId] LocationOfInterest ownerId
                 * @property {ground.v1beta1.IAuditInfo|null} [created] LocationOfInterest created
                 * @property {ground.v1beta1.IAuditInfo|null} [lastModified] LocationOfInterest lastModified
                 * @property {string|null} [customTag] LocationOfInterest customTag
                 * @property {ground.v1beta1.LocationOfInterest.Source|null} [source] LocationOfInterest source
                 * @property {Object.<string,ground.v1beta1.LocationOfInterest.IProperty>|null} [properties] LocationOfInterest properties
                 */
    
                /**
                 * Constructs a new LocationOfInterest.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a LocationOfInterest.
                 * @implements ILocationOfInterest
                 * @constructor
                 * @param {ground.v1beta1.ILocationOfInterest=} [properties] Properties to set
                 */
                function LocationOfInterest(properties) {
                    this.properties = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LocationOfInterest id.
                 * @member {string} id
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.id = "";
    
                /**
                 * LocationOfInterest jobId.
                 * @member {string} jobId
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.jobId = "";
    
                /**
                 * LocationOfInterest geometry.
                 * @member {ground.v1beta1.IGeometry|null|undefined} geometry
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.geometry = null;
    
                /**
                 * LocationOfInterest submissionCount.
                 * @member {number} submissionCount
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.submissionCount = 0;
    
                /**
                 * LocationOfInterest ownerId.
                 * @member {string} ownerId
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.ownerId = "";
    
                /**
                 * LocationOfInterest created.
                 * @member {ground.v1beta1.IAuditInfo|null|undefined} created
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.created = null;
    
                /**
                 * LocationOfInterest lastModified.
                 * @member {ground.v1beta1.IAuditInfo|null|undefined} lastModified
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.lastModified = null;
    
                /**
                 * LocationOfInterest customTag.
                 * @member {string} customTag
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.customTag = "";
    
                /**
                 * LocationOfInterest source.
                 * @member {ground.v1beta1.LocationOfInterest.Source} source
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.source = 0;
    
                /**
                 * LocationOfInterest properties.
                 * @member {Object.<string,ground.v1beta1.LocationOfInterest.IProperty>} properties
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 */
                LocationOfInterest.prototype.properties = $util.emptyObject;
    
                /**
                 * Creates a new LocationOfInterest instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {ground.v1beta1.ILocationOfInterest=} [properties] Properties to set
                 * @returns {ground.v1beta1.LocationOfInterest} LocationOfInterest instance
                 */
                LocationOfInterest.create = function create(properties) {
                    return new LocationOfInterest(properties);
                };
    
                /**
                 * Encodes the specified LocationOfInterest message. Does not implicitly {@link ground.v1beta1.LocationOfInterest.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {ground.v1beta1.ILocationOfInterest} message LocationOfInterest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationOfInterest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.jobId);
                    if (message.geometry != null && Object.hasOwnProperty.call(message, "geometry"))
                        $root.ground.v1beta1.Geometry.encode(message.geometry, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.submissionCount != null && Object.hasOwnProperty.call(message, "submissionCount"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.submissionCount);
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.ownerId);
                    if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                        $root.ground.v1beta1.AuditInfo.encode(message.created, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.lastModified != null && Object.hasOwnProperty.call(message, "lastModified"))
                        $root.ground.v1beta1.AuditInfo.encode(message.lastModified, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.customTag != null && Object.hasOwnProperty.call(message, "customTag"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.customTag);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.source);
                    if (message.properties != null && Object.hasOwnProperty.call(message, "properties"))
                        for (var keys = Object.keys(message.properties), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.ground.v1beta1.LocationOfInterest.Property.encode(message.properties[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };
    
                /**
                 * Encodes the specified LocationOfInterest message, length delimited. Does not implicitly {@link ground.v1beta1.LocationOfInterest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {ground.v1beta1.ILocationOfInterest} message LocationOfInterest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationOfInterest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LocationOfInterest message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.LocationOfInterest} LocationOfInterest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationOfInterest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.LocationOfInterest(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.jobId = reader.string();
                                break;
                            }
                        case 3: {
                                message.geometry = $root.ground.v1beta1.Geometry.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.submissionCount = reader.int32();
                                break;
                            }
                        case 5: {
                                message.ownerId = reader.string();
                                break;
                            }
                        case 6: {
                                message.created = $root.ground.v1beta1.AuditInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.lastModified = $root.ground.v1beta1.AuditInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.customTag = reader.string();
                                break;
                            }
                        case 9: {
                                message.source = reader.int32();
                                break;
                            }
                        case 10: {
                                if (message.properties === $util.emptyObject)
                                    message.properties = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = null;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = $root.ground.v1beta1.LocationOfInterest.Property.decode(reader, reader.uint32());
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.properties[key] = value;
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LocationOfInterest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.LocationOfInterest} LocationOfInterest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationOfInterest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LocationOfInterest message.
                 * @function verify
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocationOfInterest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.jobId != null && message.hasOwnProperty("jobId"))
                        if (!$util.isString(message.jobId))
                            return "jobId: string expected";
                    if (message.geometry != null && message.hasOwnProperty("geometry")) {
                        var error = $root.ground.v1beta1.Geometry.verify(message.geometry);
                        if (error)
                            return "geometry." + error;
                    }
                    if (message.submissionCount != null && message.hasOwnProperty("submissionCount"))
                        if (!$util.isInteger(message.submissionCount))
                            return "submissionCount: integer expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isString(message.ownerId))
                            return "ownerId: string expected";
                    if (message.created != null && message.hasOwnProperty("created")) {
                        var error = $root.ground.v1beta1.AuditInfo.verify(message.created);
                        if (error)
                            return "created." + error;
                    }
                    if (message.lastModified != null && message.hasOwnProperty("lastModified")) {
                        var error = $root.ground.v1beta1.AuditInfo.verify(message.lastModified);
                        if (error)
                            return "lastModified." + error;
                    }
                    if (message.customTag != null && message.hasOwnProperty("customTag"))
                        if (!$util.isString(message.customTag))
                            return "customTag: string expected";
                    if (message.source != null && message.hasOwnProperty("source"))
                        switch (message.source) {
                        default:
                            return "source: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.properties != null && message.hasOwnProperty("properties")) {
                        if (!$util.isObject(message.properties))
                            return "properties: object expected";
                        var key = Object.keys(message.properties);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.ground.v1beta1.LocationOfInterest.Property.verify(message.properties[key[i]]);
                            if (error)
                                return "properties." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a LocationOfInterest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.LocationOfInterest} LocationOfInterest
                 */
                LocationOfInterest.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.LocationOfInterest)
                        return object;
                    var message = new $root.ground.v1beta1.LocationOfInterest();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.jobId != null)
                        message.jobId = String(object.jobId);
                    if (object.geometry != null) {
                        if (typeof object.geometry !== "object")
                            throw TypeError(".ground.v1beta1.LocationOfInterest.geometry: object expected");
                        message.geometry = $root.ground.v1beta1.Geometry.fromObject(object.geometry);
                    }
                    if (object.submissionCount != null)
                        message.submissionCount = object.submissionCount | 0;
                    if (object.ownerId != null)
                        message.ownerId = String(object.ownerId);
                    if (object.created != null) {
                        if (typeof object.created !== "object")
                            throw TypeError(".ground.v1beta1.LocationOfInterest.created: object expected");
                        message.created = $root.ground.v1beta1.AuditInfo.fromObject(object.created);
                    }
                    if (object.lastModified != null) {
                        if (typeof object.lastModified !== "object")
                            throw TypeError(".ground.v1beta1.LocationOfInterest.lastModified: object expected");
                        message.lastModified = $root.ground.v1beta1.AuditInfo.fromObject(object.lastModified);
                    }
                    if (object.customTag != null)
                        message.customTag = String(object.customTag);
                    switch (object.source) {
                    default:
                        if (typeof object.source === "number") {
                            message.source = object.source;
                            break;
                        }
                        break;
                    case "SOURCE_UNSPECIFIED":
                    case 0:
                        message.source = 0;
                        break;
                    case "IMPORTED":
                    case 1:
                        message.source = 1;
                        break;
                    case "FIELD_DATA":
                    case 2:
                        message.source = 2;
                        break;
                    }
                    if (object.properties) {
                        if (typeof object.properties !== "object")
                            throw TypeError(".ground.v1beta1.LocationOfInterest.properties: object expected");
                        message.properties = {};
                        for (var keys = Object.keys(object.properties), i = 0; i < keys.length; ++i) {
                            if (typeof object.properties[keys[i]] !== "object")
                                throw TypeError(".ground.v1beta1.LocationOfInterest.properties: object expected");
                            message.properties[keys[i]] = $root.ground.v1beta1.LocationOfInterest.Property.fromObject(object.properties[keys[i]]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a LocationOfInterest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {ground.v1beta1.LocationOfInterest} message LocationOfInterest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocationOfInterest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.properties = {};
                    if (options.defaults) {
                        object.id = "";
                        object.jobId = "";
                        object.geometry = null;
                        object.submissionCount = 0;
                        object.ownerId = "";
                        object.created = null;
                        object.lastModified = null;
                        object.customTag = "";
                        object.source = options.enums === String ? "SOURCE_UNSPECIFIED" : 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.jobId != null && message.hasOwnProperty("jobId"))
                        object.jobId = message.jobId;
                    if (message.geometry != null && message.hasOwnProperty("geometry"))
                        object.geometry = $root.ground.v1beta1.Geometry.toObject(message.geometry, options);
                    if (message.submissionCount != null && message.hasOwnProperty("submissionCount"))
                        object.submissionCount = message.submissionCount;
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        object.ownerId = message.ownerId;
                    if (message.created != null && message.hasOwnProperty("created"))
                        object.created = $root.ground.v1beta1.AuditInfo.toObject(message.created, options);
                    if (message.lastModified != null && message.hasOwnProperty("lastModified"))
                        object.lastModified = $root.ground.v1beta1.AuditInfo.toObject(message.lastModified, options);
                    if (message.customTag != null && message.hasOwnProperty("customTag"))
                        object.customTag = message.customTag;
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = options.enums === String ? $root.ground.v1beta1.LocationOfInterest.Source[message.source] === undefined ? message.source : $root.ground.v1beta1.LocationOfInterest.Source[message.source] : message.source;
                    var keys2;
                    if (message.properties && (keys2 = Object.keys(message.properties)).length) {
                        object.properties = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.properties[keys2[j]] = $root.ground.v1beta1.LocationOfInterest.Property.toObject(message.properties[keys2[j]], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this LocationOfInterest to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocationOfInterest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for LocationOfInterest
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.LocationOfInterest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocationOfInterest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.LocationOfInterest";
                };
    
                /**
                 * Source enum.
                 * @name ground.v1beta1.LocationOfInterest.Source
                 * @enum {number}
                 * @property {number} SOURCE_UNSPECIFIED=0 SOURCE_UNSPECIFIED value
                 * @property {number} IMPORTED=1 IMPORTED value
                 * @property {number} FIELD_DATA=2 FIELD_DATA value
                 */
                LocationOfInterest.Source = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SOURCE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "IMPORTED"] = 1;
                    values[valuesById[2] = "FIELD_DATA"] = 2;
                    return values;
                })();
    
                LocationOfInterest.Property = (function() {
    
                    /**
                     * Properties of a Property.
                     * @memberof ground.v1beta1.LocationOfInterest
                     * @interface IProperty
                     * @property {string|null} [stringValue] Property stringValue
                     * @property {number|null} [numericValue] Property numericValue
                     */
    
                    /**
                     * Constructs a new Property.
                     * @memberof ground.v1beta1.LocationOfInterest
                     * @classdesc Represents a Property.
                     * @implements IProperty
                     * @constructor
                     * @param {ground.v1beta1.LocationOfInterest.IProperty=} [properties] Properties to set
                     */
                    function Property(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * Property stringValue.
                     * @member {string|null|undefined} stringValue
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @instance
                     */
                    Property.prototype.stringValue = null;
    
                    /**
                     * Property numericValue.
                     * @member {number|null|undefined} numericValue
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @instance
                     */
                    Property.prototype.numericValue = null;
    
                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;
    
                    /**
                     * Property propertyType.
                     * @member {"stringValue"|"numericValue"|undefined} propertyType
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @instance
                     */
                    Object.defineProperty(Property.prototype, "propertyType", {
                        get: $util.oneOfGetter($oneOfFields = ["stringValue", "numericValue"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });
    
                    /**
                     * Creates a new Property instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {ground.v1beta1.LocationOfInterest.IProperty=} [properties] Properties to set
                     * @returns {ground.v1beta1.LocationOfInterest.Property} Property instance
                     */
                    Property.create = function create(properties) {
                        return new Property(properties);
                    };
    
                    /**
                     * Encodes the specified Property message. Does not implicitly {@link ground.v1beta1.LocationOfInterest.Property.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {ground.v1beta1.LocationOfInterest.IProperty} message Property message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Property.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.stringValue);
                        if (message.numericValue != null && Object.hasOwnProperty.call(message, "numericValue"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.numericValue);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified Property message, length delimited. Does not implicitly {@link ground.v1beta1.LocationOfInterest.Property.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {ground.v1beta1.LocationOfInterest.IProperty} message Property message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Property.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a Property message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.LocationOfInterest.Property} Property
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Property.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.LocationOfInterest.Property();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.stringValue = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.numericValue = reader.double();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a Property message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.LocationOfInterest.Property} Property
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Property.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a Property message.
                     * @function verify
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Property.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                            properties.propertyType = 1;
                            if (!$util.isString(message.stringValue))
                                return "stringValue: string expected";
                        }
                        if (message.numericValue != null && message.hasOwnProperty("numericValue")) {
                            if (properties.propertyType === 1)
                                return "propertyType: multiple values";
                            properties.propertyType = 1;
                            if (typeof message.numericValue !== "number")
                                return "numericValue: number expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a Property message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.LocationOfInterest.Property} Property
                     */
                    Property.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.LocationOfInterest.Property)
                            return object;
                        var message = new $root.ground.v1beta1.LocationOfInterest.Property();
                        if (object.stringValue != null)
                            message.stringValue = String(object.stringValue);
                        if (object.numericValue != null)
                            message.numericValue = Number(object.numericValue);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a Property message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {ground.v1beta1.LocationOfInterest.Property} message Property
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Property.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                            object.stringValue = message.stringValue;
                            if (options.oneofs)
                                object.propertyType = "stringValue";
                        }
                        if (message.numericValue != null && message.hasOwnProperty("numericValue")) {
                            object.numericValue = options.json && !isFinite(message.numericValue) ? String(message.numericValue) : message.numericValue;
                            if (options.oneofs)
                                object.propertyType = "numericValue";
                        }
                        return object;
                    };
    
                    /**
                     * Converts this Property to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Property.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for Property
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.LocationOfInterest.Property
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Property.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.LocationOfInterest.Property";
                    };
    
                    return Property;
                })();
    
                return LocationOfInterest;
            })();
    
            v1beta1.Submission = (function() {
    
                /**
                 * Properties of a Submission.
                 * @memberof ground.v1beta1
                 * @interface ISubmission
                 * @property {string|null} [id] Submission id
                 * @property {string|null} [loiId] Submission loiId
                 * @property {number|null} [index] Submission index
                 * @property {string|null} [jobId] Submission jobId
                 * @property {string|null} [ownerId] Submission ownerId
                 * @property {ground.v1beta1.IAuditInfo|null} [created] Submission created
                 * @property {ground.v1beta1.IAuditInfo|null} [lastModified] Submission lastModified
                 * @property {Array.<ground.v1beta1.ITaskData>|null} [taskData] Submission taskData
                 */
    
                /**
                 * Constructs a new Submission.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Submission.
                 * @implements ISubmission
                 * @constructor
                 * @param {ground.v1beta1.ISubmission=} [properties] Properties to set
                 */
                function Submission(properties) {
                    this.taskData = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Submission id.
                 * @member {string} id
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.id = "";
    
                /**
                 * Submission loiId.
                 * @member {string} loiId
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.loiId = "";
    
                /**
                 * Submission index.
                 * @member {number} index
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.index = 0;
    
                /**
                 * Submission jobId.
                 * @member {string} jobId
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.jobId = "";
    
                /**
                 * Submission ownerId.
                 * @member {string} ownerId
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.ownerId = "";
    
                /**
                 * Submission created.
                 * @member {ground.v1beta1.IAuditInfo|null|undefined} created
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.created = null;
    
                /**
                 * Submission lastModified.
                 * @member {ground.v1beta1.IAuditInfo|null|undefined} lastModified
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.lastModified = null;
    
                /**
                 * Submission taskData.
                 * @member {Array.<ground.v1beta1.ITaskData>} taskData
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 */
                Submission.prototype.taskData = $util.emptyArray;
    
                /**
                 * Creates a new Submission instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {ground.v1beta1.ISubmission=} [properties] Properties to set
                 * @returns {ground.v1beta1.Submission} Submission instance
                 */
                Submission.create = function create(properties) {
                    return new Submission(properties);
                };
    
                /**
                 * Encodes the specified Submission message. Does not implicitly {@link ground.v1beta1.Submission.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {ground.v1beta1.ISubmission} message Submission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Submission.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.loiId != null && Object.hasOwnProperty.call(message, "loiId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.loiId);
                    if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.index);
                    if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.jobId);
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.ownerId);
                    if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                        $root.ground.v1beta1.AuditInfo.encode(message.created, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.lastModified != null && Object.hasOwnProperty.call(message, "lastModified"))
                        $root.ground.v1beta1.AuditInfo.encode(message.lastModified, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.taskData != null && message.taskData.length)
                        for (var i = 0; i < message.taskData.length; ++i)
                            $root.ground.v1beta1.TaskData.encode(message.taskData[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Submission message, length delimited. Does not implicitly {@link ground.v1beta1.Submission.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {ground.v1beta1.ISubmission} message Submission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Submission.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Submission message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Submission} Submission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Submission.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Submission();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.loiId = reader.string();
                                break;
                            }
                        case 3: {
                                message.index = reader.int32();
                                break;
                            }
                        case 4: {
                                message.jobId = reader.string();
                                break;
                            }
                        case 5: {
                                message.ownerId = reader.string();
                                break;
                            }
                        case 6: {
                                message.created = $root.ground.v1beta1.AuditInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.lastModified = $root.ground.v1beta1.AuditInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.taskData && message.taskData.length))
                                    message.taskData = [];
                                message.taskData.push($root.ground.v1beta1.TaskData.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Submission message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Submission} Submission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Submission.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Submission message.
                 * @function verify
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Submission.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.loiId != null && message.hasOwnProperty("loiId"))
                        if (!$util.isString(message.loiId))
                            return "loiId: string expected";
                    if (message.index != null && message.hasOwnProperty("index"))
                        if (!$util.isInteger(message.index))
                            return "index: integer expected";
                    if (message.jobId != null && message.hasOwnProperty("jobId"))
                        if (!$util.isString(message.jobId))
                            return "jobId: string expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isString(message.ownerId))
                            return "ownerId: string expected";
                    if (message.created != null && message.hasOwnProperty("created")) {
                        var error = $root.ground.v1beta1.AuditInfo.verify(message.created);
                        if (error)
                            return "created." + error;
                    }
                    if (message.lastModified != null && message.hasOwnProperty("lastModified")) {
                        var error = $root.ground.v1beta1.AuditInfo.verify(message.lastModified);
                        if (error)
                            return "lastModified." + error;
                    }
                    if (message.taskData != null && message.hasOwnProperty("taskData")) {
                        if (!Array.isArray(message.taskData))
                            return "taskData: array expected";
                        for (var i = 0; i < message.taskData.length; ++i) {
                            var error = $root.ground.v1beta1.TaskData.verify(message.taskData[i]);
                            if (error)
                                return "taskData." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Submission message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Submission} Submission
                 */
                Submission.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Submission)
                        return object;
                    var message = new $root.ground.v1beta1.Submission();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.loiId != null)
                        message.loiId = String(object.loiId);
                    if (object.index != null)
                        message.index = object.index | 0;
                    if (object.jobId != null)
                        message.jobId = String(object.jobId);
                    if (object.ownerId != null)
                        message.ownerId = String(object.ownerId);
                    if (object.created != null) {
                        if (typeof object.created !== "object")
                            throw TypeError(".ground.v1beta1.Submission.created: object expected");
                        message.created = $root.ground.v1beta1.AuditInfo.fromObject(object.created);
                    }
                    if (object.lastModified != null) {
                        if (typeof object.lastModified !== "object")
                            throw TypeError(".ground.v1beta1.Submission.lastModified: object expected");
                        message.lastModified = $root.ground.v1beta1.AuditInfo.fromObject(object.lastModified);
                    }
                    if (object.taskData) {
                        if (!Array.isArray(object.taskData))
                            throw TypeError(".ground.v1beta1.Submission.taskData: array expected");
                        message.taskData = [];
                        for (var i = 0; i < object.taskData.length; ++i) {
                            if (typeof object.taskData[i] !== "object")
                                throw TypeError(".ground.v1beta1.Submission.taskData: object expected");
                            message.taskData[i] = $root.ground.v1beta1.TaskData.fromObject(object.taskData[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Submission message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {ground.v1beta1.Submission} message Submission
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Submission.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.taskData = [];
                    if (options.defaults) {
                        object.id = "";
                        object.loiId = "";
                        object.index = 0;
                        object.jobId = "";
                        object.ownerId = "";
                        object.created = null;
                        object.lastModified = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.loiId != null && message.hasOwnProperty("loiId"))
                        object.loiId = message.loiId;
                    if (message.index != null && message.hasOwnProperty("index"))
                        object.index = message.index;
                    if (message.jobId != null && message.hasOwnProperty("jobId"))
                        object.jobId = message.jobId;
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        object.ownerId = message.ownerId;
                    if (message.created != null && message.hasOwnProperty("created"))
                        object.created = $root.ground.v1beta1.AuditInfo.toObject(message.created, options);
                    if (message.lastModified != null && message.hasOwnProperty("lastModified"))
                        object.lastModified = $root.ground.v1beta1.AuditInfo.toObject(message.lastModified, options);
                    if (message.taskData && message.taskData.length) {
                        object.taskData = [];
                        for (var j = 0; j < message.taskData.length; ++j)
                            object.taskData[j] = $root.ground.v1beta1.TaskData.toObject(message.taskData[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Submission to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Submission
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Submission.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Submission
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Submission
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Submission.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Submission";
                };
    
                return Submission;
            })();
    
            v1beta1.TaskData = (function() {
    
                /**
                 * Properties of a TaskData.
                 * @memberof ground.v1beta1
                 * @interface ITaskData
                 * @property {string|null} [id] TaskData id
                 * @property {string|null} [taskId] TaskData taskId
                 * @property {boolean|null} [skipped] TaskData skipped
                 * @property {ground.v1beta1.TaskData.ITextResponse|null} [textResponse] TaskData textResponse
                 * @property {ground.v1beta1.TaskData.INumberResponse|null} [numberResponse] TaskData numberResponse
                 * @property {ground.v1beta1.TaskData.IDateTimeResponse|null} [dateTimeResponse] TaskData dateTimeResponse
                 * @property {ground.v1beta1.TaskData.IMultipleChoiceResponses|null} [multipleChoiceResponses] TaskData multipleChoiceResponses
                 * @property {ground.v1beta1.TaskData.IDrawGeometryResult|null} [drawGeometryResult] TaskData drawGeometryResult
                 * @property {ground.v1beta1.TaskData.ICaptureLocationResult|null} [captureLocationResult] TaskData captureLocationResult
                 * @property {ground.v1beta1.TaskData.ITakePhotoResult|null} [takePhotoResult] TaskData takePhotoResult
                 */
    
                /**
                 * Constructs a new TaskData.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a TaskData.
                 * @implements ITaskData
                 * @constructor
                 * @param {ground.v1beta1.ITaskData=} [properties] Properties to set
                 */
                function TaskData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TaskData id.
                 * @member {string} id
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.id = "";
    
                /**
                 * TaskData taskId.
                 * @member {string} taskId
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.taskId = "";
    
                /**
                 * TaskData skipped.
                 * @member {boolean} skipped
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.skipped = false;
    
                /**
                 * TaskData textResponse.
                 * @member {ground.v1beta1.TaskData.ITextResponse|null|undefined} textResponse
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.textResponse = null;
    
                /**
                 * TaskData numberResponse.
                 * @member {ground.v1beta1.TaskData.INumberResponse|null|undefined} numberResponse
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.numberResponse = null;
    
                /**
                 * TaskData dateTimeResponse.
                 * @member {ground.v1beta1.TaskData.IDateTimeResponse|null|undefined} dateTimeResponse
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.dateTimeResponse = null;
    
                /**
                 * TaskData multipleChoiceResponses.
                 * @member {ground.v1beta1.TaskData.IMultipleChoiceResponses|null|undefined} multipleChoiceResponses
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.multipleChoiceResponses = null;
    
                /**
                 * TaskData drawGeometryResult.
                 * @member {ground.v1beta1.TaskData.IDrawGeometryResult|null|undefined} drawGeometryResult
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.drawGeometryResult = null;
    
                /**
                 * TaskData captureLocationResult.
                 * @member {ground.v1beta1.TaskData.ICaptureLocationResult|null|undefined} captureLocationResult
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.captureLocationResult = null;
    
                /**
                 * TaskData takePhotoResult.
                 * @member {ground.v1beta1.TaskData.ITakePhotoResult|null|undefined} takePhotoResult
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                TaskData.prototype.takePhotoResult = null;
    
                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;
    
                /**
                 * TaskData taskDataType.
                 * @member {"textResponse"|"numberResponse"|"dateTimeResponse"|"multipleChoiceResponses"|"drawGeometryResult"|"captureLocationResult"|"takePhotoResult"|undefined} taskDataType
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 */
                Object.defineProperty(TaskData.prototype, "taskDataType", {
                    get: $util.oneOfGetter($oneOfFields = ["textResponse", "numberResponse", "dateTimeResponse", "multipleChoiceResponses", "drawGeometryResult", "captureLocationResult", "takePhotoResult"]),
                    set: $util.oneOfSetter($oneOfFields)
                });
    
                /**
                 * Creates a new TaskData instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {ground.v1beta1.ITaskData=} [properties] Properties to set
                 * @returns {ground.v1beta1.TaskData} TaskData instance
                 */
                TaskData.create = function create(properties) {
                    return new TaskData(properties);
                };
    
                /**
                 * Encodes the specified TaskData message. Does not implicitly {@link ground.v1beta1.TaskData.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {ground.v1beta1.ITaskData} message TaskData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
                    if (message.skipped != null && Object.hasOwnProperty.call(message, "skipped"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipped);
                    if (message.textResponse != null && Object.hasOwnProperty.call(message, "textResponse"))
                        $root.ground.v1beta1.TaskData.TextResponse.encode(message.textResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.numberResponse != null && Object.hasOwnProperty.call(message, "numberResponse"))
                        $root.ground.v1beta1.TaskData.NumberResponse.encode(message.numberResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.dateTimeResponse != null && Object.hasOwnProperty.call(message, "dateTimeResponse"))
                        $root.ground.v1beta1.TaskData.DateTimeResponse.encode(message.dateTimeResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.multipleChoiceResponses != null && Object.hasOwnProperty.call(message, "multipleChoiceResponses"))
                        $root.ground.v1beta1.TaskData.MultipleChoiceResponses.encode(message.multipleChoiceResponses, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.drawGeometryResult != null && Object.hasOwnProperty.call(message, "drawGeometryResult"))
                        $root.ground.v1beta1.TaskData.DrawGeometryResult.encode(message.drawGeometryResult, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.captureLocationResult != null && Object.hasOwnProperty.call(message, "captureLocationResult"))
                        $root.ground.v1beta1.TaskData.CaptureLocationResult.encode(message.captureLocationResult, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.takePhotoResult != null && Object.hasOwnProperty.call(message, "takePhotoResult"))
                        $root.ground.v1beta1.TaskData.TakePhotoResult.encode(message.takePhotoResult, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified TaskData message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {ground.v1beta1.ITaskData} message TaskData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TaskData message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.TaskData} TaskData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskId = reader.string();
                                break;
                            }
                        case 3: {
                                message.skipped = reader.bool();
                                break;
                            }
                        case 4: {
                                message.textResponse = $root.ground.v1beta1.TaskData.TextResponse.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.numberResponse = $root.ground.v1beta1.TaskData.NumberResponse.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.dateTimeResponse = $root.ground.v1beta1.TaskData.DateTimeResponse.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.multipleChoiceResponses = $root.ground.v1beta1.TaskData.MultipleChoiceResponses.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.drawGeometryResult = $root.ground.v1beta1.TaskData.DrawGeometryResult.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.captureLocationResult = $root.ground.v1beta1.TaskData.CaptureLocationResult.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.takePhotoResult = $root.ground.v1beta1.TaskData.TakePhotoResult.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TaskData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.TaskData} TaskData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TaskData message.
                 * @function verify
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        if (!$util.isString(message.taskId))
                            return "taskId: string expected";
                    if (message.skipped != null && message.hasOwnProperty("skipped"))
                        if (typeof message.skipped !== "boolean")
                            return "skipped: boolean expected";
                    if (message.textResponse != null && message.hasOwnProperty("textResponse")) {
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.TextResponse.verify(message.textResponse);
                            if (error)
                                return "textResponse." + error;
                        }
                    }
                    if (message.numberResponse != null && message.hasOwnProperty("numberResponse")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.NumberResponse.verify(message.numberResponse);
                            if (error)
                                return "numberResponse." + error;
                        }
                    }
                    if (message.dateTimeResponse != null && message.hasOwnProperty("dateTimeResponse")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.DateTimeResponse.verify(message.dateTimeResponse);
                            if (error)
                                return "dateTimeResponse." + error;
                        }
                    }
                    if (message.multipleChoiceResponses != null && message.hasOwnProperty("multipleChoiceResponses")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.MultipleChoiceResponses.verify(message.multipleChoiceResponses);
                            if (error)
                                return "multipleChoiceResponses." + error;
                        }
                    }
                    if (message.drawGeometryResult != null && message.hasOwnProperty("drawGeometryResult")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.DrawGeometryResult.verify(message.drawGeometryResult);
                            if (error)
                                return "drawGeometryResult." + error;
                        }
                    }
                    if (message.captureLocationResult != null && message.hasOwnProperty("captureLocationResult")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.CaptureLocationResult.verify(message.captureLocationResult);
                            if (error)
                                return "captureLocationResult." + error;
                        }
                    }
                    if (message.takePhotoResult != null && message.hasOwnProperty("takePhotoResult")) {
                        if (properties.taskDataType === 1)
                            return "taskDataType: multiple values";
                        properties.taskDataType = 1;
                        {
                            var error = $root.ground.v1beta1.TaskData.TakePhotoResult.verify(message.takePhotoResult);
                            if (error)
                                return "takePhotoResult." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a TaskData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.TaskData} TaskData
                 */
                TaskData.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.TaskData)
                        return object;
                    var message = new $root.ground.v1beta1.TaskData();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.taskId != null)
                        message.taskId = String(object.taskId);
                    if (object.skipped != null)
                        message.skipped = Boolean(object.skipped);
                    if (object.textResponse != null) {
                        if (typeof object.textResponse !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.textResponse: object expected");
                        message.textResponse = $root.ground.v1beta1.TaskData.TextResponse.fromObject(object.textResponse);
                    }
                    if (object.numberResponse != null) {
                        if (typeof object.numberResponse !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.numberResponse: object expected");
                        message.numberResponse = $root.ground.v1beta1.TaskData.NumberResponse.fromObject(object.numberResponse);
                    }
                    if (object.dateTimeResponse != null) {
                        if (typeof object.dateTimeResponse !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.dateTimeResponse: object expected");
                        message.dateTimeResponse = $root.ground.v1beta1.TaskData.DateTimeResponse.fromObject(object.dateTimeResponse);
                    }
                    if (object.multipleChoiceResponses != null) {
                        if (typeof object.multipleChoiceResponses !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.multipleChoiceResponses: object expected");
                        message.multipleChoiceResponses = $root.ground.v1beta1.TaskData.MultipleChoiceResponses.fromObject(object.multipleChoiceResponses);
                    }
                    if (object.drawGeometryResult != null) {
                        if (typeof object.drawGeometryResult !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.drawGeometryResult: object expected");
                        message.drawGeometryResult = $root.ground.v1beta1.TaskData.DrawGeometryResult.fromObject(object.drawGeometryResult);
                    }
                    if (object.captureLocationResult != null) {
                        if (typeof object.captureLocationResult !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.captureLocationResult: object expected");
                        message.captureLocationResult = $root.ground.v1beta1.TaskData.CaptureLocationResult.fromObject(object.captureLocationResult);
                    }
                    if (object.takePhotoResult != null) {
                        if (typeof object.takePhotoResult !== "object")
                            throw TypeError(".ground.v1beta1.TaskData.takePhotoResult: object expected");
                        message.takePhotoResult = $root.ground.v1beta1.TaskData.TakePhotoResult.fromObject(object.takePhotoResult);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a TaskData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {ground.v1beta1.TaskData} message TaskData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.taskId = "";
                        object.skipped = false;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.taskId != null && message.hasOwnProperty("taskId"))
                        object.taskId = message.taskId;
                    if (message.skipped != null && message.hasOwnProperty("skipped"))
                        object.skipped = message.skipped;
                    if (message.textResponse != null && message.hasOwnProperty("textResponse")) {
                        object.textResponse = $root.ground.v1beta1.TaskData.TextResponse.toObject(message.textResponse, options);
                        if (options.oneofs)
                            object.taskDataType = "textResponse";
                    }
                    if (message.numberResponse != null && message.hasOwnProperty("numberResponse")) {
                        object.numberResponse = $root.ground.v1beta1.TaskData.NumberResponse.toObject(message.numberResponse, options);
                        if (options.oneofs)
                            object.taskDataType = "numberResponse";
                    }
                    if (message.dateTimeResponse != null && message.hasOwnProperty("dateTimeResponse")) {
                        object.dateTimeResponse = $root.ground.v1beta1.TaskData.DateTimeResponse.toObject(message.dateTimeResponse, options);
                        if (options.oneofs)
                            object.taskDataType = "dateTimeResponse";
                    }
                    if (message.multipleChoiceResponses != null && message.hasOwnProperty("multipleChoiceResponses")) {
                        object.multipleChoiceResponses = $root.ground.v1beta1.TaskData.MultipleChoiceResponses.toObject(message.multipleChoiceResponses, options);
                        if (options.oneofs)
                            object.taskDataType = "multipleChoiceResponses";
                    }
                    if (message.drawGeometryResult != null && message.hasOwnProperty("drawGeometryResult")) {
                        object.drawGeometryResult = $root.ground.v1beta1.TaskData.DrawGeometryResult.toObject(message.drawGeometryResult, options);
                        if (options.oneofs)
                            object.taskDataType = "drawGeometryResult";
                    }
                    if (message.captureLocationResult != null && message.hasOwnProperty("captureLocationResult")) {
                        object.captureLocationResult = $root.ground.v1beta1.TaskData.CaptureLocationResult.toObject(message.captureLocationResult, options);
                        if (options.oneofs)
                            object.taskDataType = "captureLocationResult";
                    }
                    if (message.takePhotoResult != null && message.hasOwnProperty("takePhotoResult")) {
                        object.takePhotoResult = $root.ground.v1beta1.TaskData.TakePhotoResult.toObject(message.takePhotoResult, options);
                        if (options.oneofs)
                            object.taskDataType = "takePhotoResult";
                    }
                    return object;
                };
    
                /**
                 * Converts this TaskData to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.TaskData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for TaskData
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.TaskData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaskData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.TaskData";
                };
    
                TaskData.TextResponse = (function() {
    
                    /**
                     * Properties of a TextResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @interface ITextResponse
                     * @property {string|null} [text] TextResponse text
                     */
    
                    /**
                     * Constructs a new TextResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a TextResponse.
                     * @implements ITextResponse
                     * @constructor
                     * @param {ground.v1beta1.TaskData.ITextResponse=} [properties] Properties to set
                     */
                    function TextResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TextResponse text.
                     * @member {string} text
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @instance
                     */
                    TextResponse.prototype.text = "";
    
                    /**
                     * Creates a new TextResponse instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.ITextResponse=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.TextResponse} TextResponse instance
                     */
                    TextResponse.create = function create(properties) {
                        return new TextResponse(properties);
                    };
    
                    /**
                     * Encodes the specified TextResponse message. Does not implicitly {@link ground.v1beta1.TaskData.TextResponse.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.ITextResponse} message TextResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TextResponse message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.TextResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.ITextResponse} message TextResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TextResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TextResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.TextResponse} TextResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.TextResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.text = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TextResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.TextResponse} TextResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TextResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TextResponse message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TextResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.text != null && message.hasOwnProperty("text"))
                            if (!$util.isString(message.text))
                                return "text: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a TextResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.TextResponse} TextResponse
                     */
                    TextResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.TextResponse)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.TextResponse();
                        if (object.text != null)
                            message.text = String(object.text);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TextResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.TextResponse} message TextResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TextResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.text = "";
                        if (message.text != null && message.hasOwnProperty("text"))
                            object.text = message.text;
                        return object;
                    };
    
                    /**
                     * Converts this TextResponse to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TextResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TextResponse
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.TextResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TextResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.TextResponse";
                    };
    
                    return TextResponse;
                })();
    
                TaskData.NumberResponse = (function() {
    
                    /**
                     * Properties of a NumberResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @interface INumberResponse
                     * @property {number|null} [number] NumberResponse number
                     */
    
                    /**
                     * Constructs a new NumberResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a NumberResponse.
                     * @implements INumberResponse
                     * @constructor
                     * @param {ground.v1beta1.TaskData.INumberResponse=} [properties] Properties to set
                     */
                    function NumberResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * NumberResponse number.
                     * @member {number} number
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @instance
                     */
                    NumberResponse.prototype.number = 0;
    
                    /**
                     * Creates a new NumberResponse instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.INumberResponse=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.NumberResponse} NumberResponse instance
                     */
                    NumberResponse.create = function create(properties) {
                        return new NumberResponse(properties);
                    };
    
                    /**
                     * Encodes the specified NumberResponse message. Does not implicitly {@link ground.v1beta1.TaskData.NumberResponse.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.INumberResponse} message NumberResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NumberResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                            writer.uint32(/* id 1, wireType 1 =*/9).double(message.number);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified NumberResponse message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.NumberResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.INumberResponse} message NumberResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NumberResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a NumberResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.NumberResponse} NumberResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NumberResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.NumberResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.number = reader.double();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a NumberResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.NumberResponse} NumberResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NumberResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a NumberResponse message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NumberResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.number != null && message.hasOwnProperty("number"))
                            if (typeof message.number !== "number")
                                return "number: number expected";
                        return null;
                    };
    
                    /**
                     * Creates a NumberResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.NumberResponse} NumberResponse
                     */
                    NumberResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.NumberResponse)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.NumberResponse();
                        if (object.number != null)
                            message.number = Number(object.number);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a NumberResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.NumberResponse} message NumberResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NumberResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.number = 0;
                        if (message.number != null && message.hasOwnProperty("number"))
                            object.number = options.json && !isFinite(message.number) ? String(message.number) : message.number;
                        return object;
                    };
    
                    /**
                     * Converts this NumberResponse to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NumberResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for NumberResponse
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.NumberResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    NumberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.NumberResponse";
                    };
    
                    return NumberResponse;
                })();
    
                TaskData.DateTimeResponse = (function() {
    
                    /**
                     * Properties of a DateTimeResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @interface IDateTimeResponse
                     * @property {google.protobuf.ITimestamp|null} [dateTime] DateTimeResponse dateTime
                     */
    
                    /**
                     * Constructs a new DateTimeResponse.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a DateTimeResponse.
                     * @implements IDateTimeResponse
                     * @constructor
                     * @param {ground.v1beta1.TaskData.IDateTimeResponse=} [properties] Properties to set
                     */
                    function DateTimeResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DateTimeResponse dateTime.
                     * @member {google.protobuf.ITimestamp|null|undefined} dateTime
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @instance
                     */
                    DateTimeResponse.prototype.dateTime = null;
    
                    /**
                     * Creates a new DateTimeResponse instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.IDateTimeResponse=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.DateTimeResponse} DateTimeResponse instance
                     */
                    DateTimeResponse.create = function create(properties) {
                        return new DateTimeResponse(properties);
                    };
    
                    /**
                     * Encodes the specified DateTimeResponse message. Does not implicitly {@link ground.v1beta1.TaskData.DateTimeResponse.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.IDateTimeResponse} message DateTimeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateTimeResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                            $root.google.protobuf.Timestamp.encode(message.dateTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DateTimeResponse message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.DateTimeResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.IDateTimeResponse} message DateTimeResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DateTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DateTimeResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.DateTimeResponse} DateTimeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateTimeResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.DateTimeResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.dateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DateTimeResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.DateTimeResponse} DateTimeResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DateTimeResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DateTimeResponse message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DateTimeResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dateTime != null && message.hasOwnProperty("dateTime")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.dateTime);
                            if (error)
                                return "dateTime." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DateTimeResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.DateTimeResponse} DateTimeResponse
                     */
                    DateTimeResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.DateTimeResponse)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.DateTimeResponse();
                        if (object.dateTime != null) {
                            if (typeof object.dateTime !== "object")
                                throw TypeError(".ground.v1beta1.TaskData.DateTimeResponse.dateTime: object expected");
                            message.dateTime = $root.google.protobuf.Timestamp.fromObject(object.dateTime);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DateTimeResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {ground.v1beta1.TaskData.DateTimeResponse} message DateTimeResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DateTimeResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.dateTime = null;
                        if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                            object.dateTime = $root.google.protobuf.Timestamp.toObject(message.dateTime, options);
                        return object;
                    };
    
                    /**
                     * Converts this DateTimeResponse to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DateTimeResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for DateTimeResponse
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.DateTimeResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DateTimeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.DateTimeResponse";
                    };
    
                    return DateTimeResponse;
                })();
    
                TaskData.MultipleChoiceResponses = (function() {
    
                    /**
                     * Properties of a MultipleChoiceResponses.
                     * @memberof ground.v1beta1.TaskData
                     * @interface IMultipleChoiceResponses
                     * @property {Array.<string>|null} [selectedOptionIds] MultipleChoiceResponses selectedOptionIds
                     * @property {string|null} [otherText] MultipleChoiceResponses otherText
                     */
    
                    /**
                     * Constructs a new MultipleChoiceResponses.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a MultipleChoiceResponses.
                     * @implements IMultipleChoiceResponses
                     * @constructor
                     * @param {ground.v1beta1.TaskData.IMultipleChoiceResponses=} [properties] Properties to set
                     */
                    function MultipleChoiceResponses(properties) {
                        this.selectedOptionIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * MultipleChoiceResponses selectedOptionIds.
                     * @member {Array.<string>} selectedOptionIds
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @instance
                     */
                    MultipleChoiceResponses.prototype.selectedOptionIds = $util.emptyArray;
    
                    /**
                     * MultipleChoiceResponses otherText.
                     * @member {string} otherText
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @instance
                     */
                    MultipleChoiceResponses.prototype.otherText = "";
    
                    /**
                     * Creates a new MultipleChoiceResponses instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {ground.v1beta1.TaskData.IMultipleChoiceResponses=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.MultipleChoiceResponses} MultipleChoiceResponses instance
                     */
                    MultipleChoiceResponses.create = function create(properties) {
                        return new MultipleChoiceResponses(properties);
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceResponses message. Does not implicitly {@link ground.v1beta1.TaskData.MultipleChoiceResponses.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {ground.v1beta1.TaskData.IMultipleChoiceResponses} message MultipleChoiceResponses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceResponses.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.selectedOptionIds != null && message.selectedOptionIds.length)
                            for (var i = 0; i < message.selectedOptionIds.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.selectedOptionIds[i]);
                        if (message.otherText != null && Object.hasOwnProperty.call(message, "otherText"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.otherText);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified MultipleChoiceResponses message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.MultipleChoiceResponses.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {ground.v1beta1.TaskData.IMultipleChoiceResponses} message MultipleChoiceResponses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MultipleChoiceResponses.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a MultipleChoiceResponses message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.MultipleChoiceResponses} MultipleChoiceResponses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceResponses.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.MultipleChoiceResponses();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.selectedOptionIds && message.selectedOptionIds.length))
                                        message.selectedOptionIds = [];
                                    message.selectedOptionIds.push(reader.string());
                                    break;
                                }
                            case 2: {
                                    message.otherText = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a MultipleChoiceResponses message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.MultipleChoiceResponses} MultipleChoiceResponses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MultipleChoiceResponses.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a MultipleChoiceResponses message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MultipleChoiceResponses.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.selectedOptionIds != null && message.hasOwnProperty("selectedOptionIds")) {
                            if (!Array.isArray(message.selectedOptionIds))
                                return "selectedOptionIds: array expected";
                            for (var i = 0; i < message.selectedOptionIds.length; ++i)
                                if (!$util.isString(message.selectedOptionIds[i]))
                                    return "selectedOptionIds: string[] expected";
                        }
                        if (message.otherText != null && message.hasOwnProperty("otherText"))
                            if (!$util.isString(message.otherText))
                                return "otherText: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a MultipleChoiceResponses message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.MultipleChoiceResponses} MultipleChoiceResponses
                     */
                    MultipleChoiceResponses.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.MultipleChoiceResponses)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.MultipleChoiceResponses();
                        if (object.selectedOptionIds) {
                            if (!Array.isArray(object.selectedOptionIds))
                                throw TypeError(".ground.v1beta1.TaskData.MultipleChoiceResponses.selectedOptionIds: array expected");
                            message.selectedOptionIds = [];
                            for (var i = 0; i < object.selectedOptionIds.length; ++i)
                                message.selectedOptionIds[i] = String(object.selectedOptionIds[i]);
                        }
                        if (object.otherText != null)
                            message.otherText = String(object.otherText);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a MultipleChoiceResponses message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {ground.v1beta1.TaskData.MultipleChoiceResponses} message MultipleChoiceResponses
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MultipleChoiceResponses.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.selectedOptionIds = [];
                        if (options.defaults)
                            object.otherText = "";
                        if (message.selectedOptionIds && message.selectedOptionIds.length) {
                            object.selectedOptionIds = [];
                            for (var j = 0; j < message.selectedOptionIds.length; ++j)
                                object.selectedOptionIds[j] = message.selectedOptionIds[j];
                        }
                        if (message.otherText != null && message.hasOwnProperty("otherText"))
                            object.otherText = message.otherText;
                        return object;
                    };
    
                    /**
                     * Converts this MultipleChoiceResponses to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MultipleChoiceResponses.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for MultipleChoiceResponses
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.MultipleChoiceResponses
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    MultipleChoiceResponses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.MultipleChoiceResponses";
                    };
    
                    return MultipleChoiceResponses;
                })();
    
                TaskData.DrawGeometryResult = (function() {
    
                    /**
                     * Properties of a DrawGeometryResult.
                     * @memberof ground.v1beta1.TaskData
                     * @interface IDrawGeometryResult
                     * @property {ground.v1beta1.IGeometry|null} [geometry] DrawGeometryResult geometry
                     */
    
                    /**
                     * Constructs a new DrawGeometryResult.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a DrawGeometryResult.
                     * @implements IDrawGeometryResult
                     * @constructor
                     * @param {ground.v1beta1.TaskData.IDrawGeometryResult=} [properties] Properties to set
                     */
                    function DrawGeometryResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DrawGeometryResult geometry.
                     * @member {ground.v1beta1.IGeometry|null|undefined} geometry
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @instance
                     */
                    DrawGeometryResult.prototype.geometry = null;
    
                    /**
                     * Creates a new DrawGeometryResult instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {ground.v1beta1.TaskData.IDrawGeometryResult=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.DrawGeometryResult} DrawGeometryResult instance
                     */
                    DrawGeometryResult.create = function create(properties) {
                        return new DrawGeometryResult(properties);
                    };
    
                    /**
                     * Encodes the specified DrawGeometryResult message. Does not implicitly {@link ground.v1beta1.TaskData.DrawGeometryResult.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {ground.v1beta1.TaskData.IDrawGeometryResult} message DrawGeometryResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DrawGeometryResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.geometry != null && Object.hasOwnProperty.call(message, "geometry"))
                            $root.ground.v1beta1.Geometry.encode(message.geometry, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DrawGeometryResult message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.DrawGeometryResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {ground.v1beta1.TaskData.IDrawGeometryResult} message DrawGeometryResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DrawGeometryResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DrawGeometryResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.DrawGeometryResult} DrawGeometryResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DrawGeometryResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.DrawGeometryResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.geometry = $root.ground.v1beta1.Geometry.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DrawGeometryResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.DrawGeometryResult} DrawGeometryResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DrawGeometryResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DrawGeometryResult message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DrawGeometryResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.geometry != null && message.hasOwnProperty("geometry")) {
                            var error = $root.ground.v1beta1.Geometry.verify(message.geometry);
                            if (error)
                                return "geometry." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a DrawGeometryResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.DrawGeometryResult} DrawGeometryResult
                     */
                    DrawGeometryResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.DrawGeometryResult)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.DrawGeometryResult();
                        if (object.geometry != null) {
                            if (typeof object.geometry !== "object")
                                throw TypeError(".ground.v1beta1.TaskData.DrawGeometryResult.geometry: object expected");
                            message.geometry = $root.ground.v1beta1.Geometry.fromObject(object.geometry);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DrawGeometryResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {ground.v1beta1.TaskData.DrawGeometryResult} message DrawGeometryResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DrawGeometryResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.geometry = null;
                        if (message.geometry != null && message.hasOwnProperty("geometry"))
                            object.geometry = $root.ground.v1beta1.Geometry.toObject(message.geometry, options);
                        return object;
                    };
    
                    /**
                     * Converts this DrawGeometryResult to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DrawGeometryResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for DrawGeometryResult
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.DrawGeometryResult
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DrawGeometryResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.DrawGeometryResult";
                    };
    
                    return DrawGeometryResult;
                })();
    
                TaskData.CaptureLocationResult = (function() {
    
                    /**
                     * Properties of a CaptureLocationResult.
                     * @memberof ground.v1beta1.TaskData
                     * @interface ICaptureLocationResult
                     * @property {ground.v1beta1.ICoordinates|null} [coordinates] CaptureLocationResult coordinates
                     * @property {number|null} [accuracy] CaptureLocationResult accuracy
                     * @property {number|null} [altitude] CaptureLocationResult altitude
                     * @property {google.protobuf.ITimestamp|null} [timestamp] CaptureLocationResult timestamp
                     */
    
                    /**
                     * Constructs a new CaptureLocationResult.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a CaptureLocationResult.
                     * @implements ICaptureLocationResult
                     * @constructor
                     * @param {ground.v1beta1.TaskData.ICaptureLocationResult=} [properties] Properties to set
                     */
                    function CaptureLocationResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * CaptureLocationResult coordinates.
                     * @member {ground.v1beta1.ICoordinates|null|undefined} coordinates
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @instance
                     */
                    CaptureLocationResult.prototype.coordinates = null;
    
                    /**
                     * CaptureLocationResult accuracy.
                     * @member {number} accuracy
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @instance
                     */
                    CaptureLocationResult.prototype.accuracy = 0;
    
                    /**
                     * CaptureLocationResult altitude.
                     * @member {number} altitude
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @instance
                     */
                    CaptureLocationResult.prototype.altitude = 0;
    
                    /**
                     * CaptureLocationResult timestamp.
                     * @member {google.protobuf.ITimestamp|null|undefined} timestamp
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @instance
                     */
                    CaptureLocationResult.prototype.timestamp = null;
    
                    /**
                     * Creates a new CaptureLocationResult instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ICaptureLocationResult=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.CaptureLocationResult} CaptureLocationResult instance
                     */
                    CaptureLocationResult.create = function create(properties) {
                        return new CaptureLocationResult(properties);
                    };
    
                    /**
                     * Encodes the specified CaptureLocationResult message. Does not implicitly {@link ground.v1beta1.TaskData.CaptureLocationResult.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ICaptureLocationResult} message CaptureLocationResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CaptureLocationResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                            $root.ground.v1beta1.Coordinates.encode(message.coordinates, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.accuracy != null && Object.hasOwnProperty.call(message, "accuracy"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.accuracy);
                        if (message.altitude != null && Object.hasOwnProperty.call(message, "altitude"))
                            writer.uint32(/* id 3, wireType 1 =*/25).double(message.altitude);
                        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                            $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified CaptureLocationResult message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.CaptureLocationResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ICaptureLocationResult} message CaptureLocationResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CaptureLocationResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a CaptureLocationResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.CaptureLocationResult} CaptureLocationResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CaptureLocationResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.CaptureLocationResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.coordinates = $root.ground.v1beta1.Coordinates.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.accuracy = reader.double();
                                    break;
                                }
                            case 3: {
                                    message.altitude = reader.double();
                                    break;
                                }
                            case 4: {
                                    message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a CaptureLocationResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.CaptureLocationResult} CaptureLocationResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CaptureLocationResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a CaptureLocationResult message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CaptureLocationResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                            var error = $root.ground.v1beta1.Coordinates.verify(message.coordinates);
                            if (error)
                                return "coordinates." + error;
                        }
                        if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                            if (typeof message.accuracy !== "number")
                                return "accuracy: number expected";
                        if (message.altitude != null && message.hasOwnProperty("altitude"))
                            if (typeof message.altitude !== "number")
                                return "altitude: number expected";
                        if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                            if (error)
                                return "timestamp." + error;
                        }
                        return null;
                    };
    
                    /**
                     * Creates a CaptureLocationResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.CaptureLocationResult} CaptureLocationResult
                     */
                    CaptureLocationResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.CaptureLocationResult)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.CaptureLocationResult();
                        if (object.coordinates != null) {
                            if (typeof object.coordinates !== "object")
                                throw TypeError(".ground.v1beta1.TaskData.CaptureLocationResult.coordinates: object expected");
                            message.coordinates = $root.ground.v1beta1.Coordinates.fromObject(object.coordinates);
                        }
                        if (object.accuracy != null)
                            message.accuracy = Number(object.accuracy);
                        if (object.altitude != null)
                            message.altitude = Number(object.altitude);
                        if (object.timestamp != null) {
                            if (typeof object.timestamp !== "object")
                                throw TypeError(".ground.v1beta1.TaskData.CaptureLocationResult.timestamp: object expected");
                            message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a CaptureLocationResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {ground.v1beta1.TaskData.CaptureLocationResult} message CaptureLocationResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CaptureLocationResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.coordinates = null;
                            object.accuracy = 0;
                            object.altitude = 0;
                            object.timestamp = null;
                        }
                        if (message.coordinates != null && message.hasOwnProperty("coordinates"))
                            object.coordinates = $root.ground.v1beta1.Coordinates.toObject(message.coordinates, options);
                        if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                            object.accuracy = options.json && !isFinite(message.accuracy) ? String(message.accuracy) : message.accuracy;
                        if (message.altitude != null && message.hasOwnProperty("altitude"))
                            object.altitude = options.json && !isFinite(message.altitude) ? String(message.altitude) : message.altitude;
                        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                            object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
                        return object;
                    };
    
                    /**
                     * Converts this CaptureLocationResult to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CaptureLocationResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for CaptureLocationResult
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.CaptureLocationResult
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CaptureLocationResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.CaptureLocationResult";
                    };
    
                    return CaptureLocationResult;
                })();
    
                TaskData.TakePhotoResult = (function() {
    
                    /**
                     * Properties of a TakePhotoResult.
                     * @memberof ground.v1beta1.TaskData
                     * @interface ITakePhotoResult
                     * @property {string|null} [photoPath] TakePhotoResult photoPath
                     */
    
                    /**
                     * Constructs a new TakePhotoResult.
                     * @memberof ground.v1beta1.TaskData
                     * @classdesc Represents a TakePhotoResult.
                     * @implements ITakePhotoResult
                     * @constructor
                     * @param {ground.v1beta1.TaskData.ITakePhotoResult=} [properties] Properties to set
                     */
                    function TakePhotoResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * TakePhotoResult photoPath.
                     * @member {string} photoPath
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @instance
                     */
                    TakePhotoResult.prototype.photoPath = "";
    
                    /**
                     * Creates a new TakePhotoResult instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ITakePhotoResult=} [properties] Properties to set
                     * @returns {ground.v1beta1.TaskData.TakePhotoResult} TakePhotoResult instance
                     */
                    TakePhotoResult.create = function create(properties) {
                        return new TakePhotoResult(properties);
                    };
    
                    /**
                     * Encodes the specified TakePhotoResult message. Does not implicitly {@link ground.v1beta1.TaskData.TakePhotoResult.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ITakePhotoResult} message TakePhotoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakePhotoResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.photoPath != null && Object.hasOwnProperty.call(message, "photoPath"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.photoPath);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified TakePhotoResult message, length delimited. Does not implicitly {@link ground.v1beta1.TaskData.TakePhotoResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {ground.v1beta1.TaskData.ITakePhotoResult} message TakePhotoResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TakePhotoResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a TakePhotoResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.TaskData.TakePhotoResult} TakePhotoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakePhotoResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.TaskData.TakePhotoResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.photoPath = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a TakePhotoResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.TaskData.TakePhotoResult} TakePhotoResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TakePhotoResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a TakePhotoResult message.
                     * @function verify
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TakePhotoResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.photoPath != null && message.hasOwnProperty("photoPath"))
                            if (!$util.isString(message.photoPath))
                                return "photoPath: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a TakePhotoResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.TaskData.TakePhotoResult} TakePhotoResult
                     */
                    TakePhotoResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.TaskData.TakePhotoResult)
                            return object;
                        var message = new $root.ground.v1beta1.TaskData.TakePhotoResult();
                        if (object.photoPath != null)
                            message.photoPath = String(object.photoPath);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a TakePhotoResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {ground.v1beta1.TaskData.TakePhotoResult} message TakePhotoResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TakePhotoResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.photoPath = "";
                        if (message.photoPath != null && message.hasOwnProperty("photoPath"))
                            object.photoPath = message.photoPath;
                        return object;
                    };
    
                    /**
                     * Converts this TakePhotoResult to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TakePhotoResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for TakePhotoResult
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.TaskData.TakePhotoResult
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TakePhotoResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.TaskData.TakePhotoResult";
                    };
    
                    return TakePhotoResult;
                })();
    
                return TaskData;
            })();
    
            v1beta1.Survey = (function() {
    
                /**
                 * Properties of a Survey.
                 * @memberof ground.v1beta1
                 * @interface ISurvey
                 * @property {string|null} [id] Survey id
                 * @property {string|null} [name] Survey name
                 * @property {string|null} [description] Survey description
                 * @property {Object.<string,ground.v1beta1.Role>|null} [acl] Survey acl
                 * @property {string|null} [ownerId] Survey ownerId
                 * @property {ground.v1beta1.Survey.IDataSharingTerms|null} [dataSharingTerms] Survey dataSharingTerms
                 * @property {ground.v1beta1.Survey.State|null} [state] Survey state
                 */
    
                /**
                 * Constructs a new Survey.
                 * @memberof ground.v1beta1
                 * @classdesc Represents a Survey.
                 * @implements ISurvey
                 * @constructor
                 * @param {ground.v1beta1.ISurvey=} [properties] Properties to set
                 */
                function Survey(properties) {
                    this.acl = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Survey id.
                 * @member {string} id
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.id = "";
    
                /**
                 * Survey name.
                 * @member {string} name
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.name = "";
    
                /**
                 * Survey description.
                 * @member {string} description
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.description = "";
    
                /**
                 * Survey acl.
                 * @member {Object.<string,ground.v1beta1.Role>} acl
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.acl = $util.emptyObject;
    
                /**
                 * Survey ownerId.
                 * @member {string} ownerId
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.ownerId = "";
    
                /**
                 * Survey dataSharingTerms.
                 * @member {ground.v1beta1.Survey.IDataSharingTerms|null|undefined} dataSharingTerms
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.dataSharingTerms = null;
    
                /**
                 * Survey state.
                 * @member {ground.v1beta1.Survey.State} state
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 */
                Survey.prototype.state = 0;
    
                /**
                 * Creates a new Survey instance using the specified properties.
                 * @function create
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {ground.v1beta1.ISurvey=} [properties] Properties to set
                 * @returns {ground.v1beta1.Survey} Survey instance
                 */
                Survey.create = function create(properties) {
                    return new Survey(properties);
                };
    
                /**
                 * Encodes the specified Survey message. Does not implicitly {@link ground.v1beta1.Survey.verify|verify} messages.
                 * @function encode
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {ground.v1beta1.ISurvey} message Survey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Survey.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.acl != null && Object.hasOwnProperty.call(message, "acl"))
                        for (var keys = Object.keys(message.acl), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int32(message.acl[keys[i]]).ldelim();
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.ownerId);
                    if (message.dataSharingTerms != null && Object.hasOwnProperty.call(message, "dataSharingTerms"))
                        $root.ground.v1beta1.Survey.DataSharingTerms.encode(message.dataSharingTerms, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.state);
                    return writer;
                };
    
                /**
                 * Encodes the specified Survey message, length delimited. Does not implicitly {@link ground.v1beta1.Survey.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {ground.v1beta1.ISurvey} message Survey message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Survey.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Survey message from the specified reader or buffer.
                 * @function decode
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {ground.v1beta1.Survey} Survey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Survey.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Survey(), key, value;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                if (message.acl === $util.emptyObject)
                                    message.acl = {};
                                var end2 = reader.uint32() + reader.pos;
                                key = "";
                                value = 0;
                                while (reader.pos < end2) {
                                    var tag2 = reader.uint32();
                                    switch (tag2 >>> 3) {
                                    case 1:
                                        key = reader.string();
                                        break;
                                    case 2:
                                        value = reader.int32();
                                        break;
                                    default:
                                        reader.skipType(tag2 & 7);
                                        break;
                                    }
                                }
                                message.acl[key] = value;
                                break;
                            }
                        case 5: {
                                message.ownerId = reader.string();
                                break;
                            }
                        case 6: {
                                message.dataSharingTerms = $root.ground.v1beta1.Survey.DataSharingTerms.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.state = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Survey message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {ground.v1beta1.Survey} Survey
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Survey.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Survey message.
                 * @function verify
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Survey.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.acl != null && message.hasOwnProperty("acl")) {
                        if (!$util.isObject(message.acl))
                            return "acl: object expected";
                        var key = Object.keys(message.acl);
                        for (var i = 0; i < key.length; ++i)
                            switch (message.acl[key[i]]) {
                            default:
                                return "acl: enum value{k:string} expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isString(message.ownerId))
                            return "ownerId: string expected";
                    if (message.dataSharingTerms != null && message.hasOwnProperty("dataSharingTerms")) {
                        var error = $root.ground.v1beta1.Survey.DataSharingTerms.verify(message.dataSharingTerms);
                        if (error)
                            return "dataSharingTerms." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };
    
                /**
                 * Creates a Survey message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {ground.v1beta1.Survey} Survey
                 */
                Survey.fromObject = function fromObject(object) {
                    if (object instanceof $root.ground.v1beta1.Survey)
                        return object;
                    var message = new $root.ground.v1beta1.Survey();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.acl) {
                        if (typeof object.acl !== "object")
                            throw TypeError(".ground.v1beta1.Survey.acl: object expected");
                        message.acl = {};
                        for (var keys = Object.keys(object.acl), i = 0; i < keys.length; ++i)
                            switch (object.acl[keys[i]]) {
                            default:
                                if (typeof object.acl[keys[i]] === "number") {
                                    message.acl[keys[i]] = object.acl[keys[i]];
                                    break;
                                }
                                break;
                            case "ROLE_UNSPECIFIED":
                            case 0:
                                message.acl[keys[i]] = 0;
                                break;
                            case "VIEWER":
                            case 1:
                                message.acl[keys[i]] = 1;
                                break;
                            case "DATA_COLLECTOR":
                            case 2:
                                message.acl[keys[i]] = 2;
                                break;
                            case "SURVEY_ORGANIZER":
                            case 3:
                                message.acl[keys[i]] = 3;
                                break;
                            }
                    }
                    if (object.ownerId != null)
                        message.ownerId = String(object.ownerId);
                    if (object.dataSharingTerms != null) {
                        if (typeof object.dataSharingTerms !== "object")
                            throw TypeError(".ground.v1beta1.Survey.dataSharingTerms: object expected");
                        message.dataSharingTerms = $root.ground.v1beta1.Survey.DataSharingTerms.fromObject(object.dataSharingTerms);
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "DRAFT":
                    case 1:
                        message.state = 1;
                        break;
                    case "READY":
                    case 2:
                        message.state = 2;
                        break;
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Survey message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {ground.v1beta1.Survey} message Survey
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Survey.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.acl = {};
                    if (options.defaults) {
                        object.id = "";
                        object.name = "";
                        object.description = "";
                        object.ownerId = "";
                        object.dataSharingTerms = null;
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    var keys2;
                    if (message.acl && (keys2 = Object.keys(message.acl)).length) {
                        object.acl = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.acl[keys2[j]] = options.enums === String ? $root.ground.v1beta1.Role[message.acl[keys2[j]]] === undefined ? message.acl[keys2[j]] : $root.ground.v1beta1.Role[message.acl[keys2[j]]] : message.acl[keys2[j]];
                    }
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        object.ownerId = message.ownerId;
                    if (message.dataSharingTerms != null && message.hasOwnProperty("dataSharingTerms"))
                        object.dataSharingTerms = $root.ground.v1beta1.Survey.DataSharingTerms.toObject(message.dataSharingTerms, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.ground.v1beta1.Survey.State[message.state] === undefined ? message.state : $root.ground.v1beta1.Survey.State[message.state] : message.state;
                    return object;
                };
    
                /**
                 * Converts this Survey to JSON.
                 * @function toJSON
                 * @memberof ground.v1beta1.Survey
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Survey.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Survey
                 * @function getTypeUrl
                 * @memberof ground.v1beta1.Survey
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Survey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/ground.v1beta1.Survey";
                };
    
                Survey.DataSharingTerms = (function() {
    
                    /**
                     * Properties of a DataSharingTerms.
                     * @memberof ground.v1beta1.Survey
                     * @interface IDataSharingTerms
                     * @property {ground.v1beta1.Survey.DataSharingTerms.Type|null} [type] DataSharingTerms type
                     * @property {string|null} [customText] DataSharingTerms customText
                     */
    
                    /**
                     * Constructs a new DataSharingTerms.
                     * @memberof ground.v1beta1.Survey
                     * @classdesc Represents a DataSharingTerms.
                     * @implements IDataSharingTerms
                     * @constructor
                     * @param {ground.v1beta1.Survey.IDataSharingTerms=} [properties] Properties to set
                     */
                    function DataSharingTerms(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * DataSharingTerms type.
                     * @member {ground.v1beta1.Survey.DataSharingTerms.Type} type
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @instance
                     */
                    DataSharingTerms.prototype.type = 0;
    
                    /**
                     * DataSharingTerms customText.
                     * @member {string} customText
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @instance
                     */
                    DataSharingTerms.prototype.customText = "";
    
                    /**
                     * Creates a new DataSharingTerms instance using the specified properties.
                     * @function create
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {ground.v1beta1.Survey.IDataSharingTerms=} [properties] Properties to set
                     * @returns {ground.v1beta1.Survey.DataSharingTerms} DataSharingTerms instance
                     */
                    DataSharingTerms.create = function create(properties) {
                        return new DataSharingTerms(properties);
                    };
    
                    /**
                     * Encodes the specified DataSharingTerms message. Does not implicitly {@link ground.v1beta1.Survey.DataSharingTerms.verify|verify} messages.
                     * @function encode
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {ground.v1beta1.Survey.IDataSharingTerms} message DataSharingTerms message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DataSharingTerms.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.customText != null && Object.hasOwnProperty.call(message, "customText"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.customText);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified DataSharingTerms message, length delimited. Does not implicitly {@link ground.v1beta1.Survey.DataSharingTerms.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {ground.v1beta1.Survey.IDataSharingTerms} message DataSharingTerms message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DataSharingTerms.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a DataSharingTerms message from the specified reader or buffer.
                     * @function decode
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {ground.v1beta1.Survey.DataSharingTerms} DataSharingTerms
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DataSharingTerms.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ground.v1beta1.Survey.DataSharingTerms();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.type = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.customText = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a DataSharingTerms message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {ground.v1beta1.Survey.DataSharingTerms} DataSharingTerms
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DataSharingTerms.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a DataSharingTerms message.
                     * @function verify
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataSharingTerms.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.customText != null && message.hasOwnProperty("customText"))
                            if (!$util.isString(message.customText))
                                return "customText: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a DataSharingTerms message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {ground.v1beta1.Survey.DataSharingTerms} DataSharingTerms
                     */
                    DataSharingTerms.fromObject = function fromObject(object) {
                        if (object instanceof $root.ground.v1beta1.Survey.DataSharingTerms)
                            return object;
                        var message = new $root.ground.v1beta1.Survey.DataSharingTerms();
                        switch (object.type) {
                        default:
                            if (typeof object.type === "number") {
                                message.type = object.type;
                                break;
                            }
                            break;
                        case "TYPE_UNSPECIFIED":
                        case 0:
                            message.type = 0;
                            break;
                        case "PRIVATE":
                        case 1:
                            message.type = 1;
                            break;
                        case "PUBLIC_CC0":
                        case 2:
                            message.type = 2;
                            break;
                        case "CUSTOM":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.customText != null)
                            message.customText = String(object.customText);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a DataSharingTerms message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {ground.v1beta1.Survey.DataSharingTerms} message DataSharingTerms
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataSharingTerms.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                            object.customText = "";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.ground.v1beta1.Survey.DataSharingTerms.Type[message.type] === undefined ? message.type : $root.ground.v1beta1.Survey.DataSharingTerms.Type[message.type] : message.type;
                        if (message.customText != null && message.hasOwnProperty("customText"))
                            object.customText = message.customText;
                        return object;
                    };
    
                    /**
                     * Converts this DataSharingTerms to JSON.
                     * @function toJSON
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataSharingTerms.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    /**
                     * Gets the default type url for DataSharingTerms
                     * @function getTypeUrl
                     * @memberof ground.v1beta1.Survey.DataSharingTerms
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    DataSharingTerms.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/ground.v1beta1.Survey.DataSharingTerms";
                    };
    
                    /**
                     * Type enum.
                     * @name ground.v1beta1.Survey.DataSharingTerms.Type
                     * @enum {number}
                     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
                     * @property {number} PRIVATE=1 PRIVATE value
                     * @property {number} PUBLIC_CC0=2 PUBLIC_CC0 value
                     * @property {number} CUSTOM=3 CUSTOM value
                     */
                    DataSharingTerms.Type = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "PRIVATE"] = 1;
                        values[valuesById[2] = "PUBLIC_CC0"] = 2;
                        values[valuesById[3] = "CUSTOM"] = 3;
                        return values;
                    })();
    
                    return DataSharingTerms;
                })();
    
                /**
                 * State enum.
                 * @name ground.v1beta1.Survey.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} DRAFT=1 DRAFT value
                 * @property {number} READY=2 READY value
                 */
                Survey.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "DRAFT"] = 1;
                    values[valuesById[2] = "READY"] = 2;
                    return values;
                })();
    
                return Survey;
            })();
    
            /**
             * Role enum.
             * @name ground.v1beta1.Role
             * @enum {number}
             * @property {number} ROLE_UNSPECIFIED=0 ROLE_UNSPECIFIED value
             * @property {number} VIEWER=1 VIEWER value
             * @property {number} DATA_COLLECTOR=2 DATA_COLLECTOR value
             * @property {number} SURVEY_ORGANIZER=3 SURVEY_ORGANIZER value
             */
            v1beta1.Role = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ROLE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "VIEWER"] = 1;
                values[valuesById[2] = "DATA_COLLECTOR"] = 2;
                values[valuesById[3] = "SURVEY_ORGANIZER"] = 3;
                return values;
            })();
    
            return v1beta1;
        })();
    
        return ground;
    })();
    
    $root.google = (function() {
    
        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};
    
        google.protobuf = (function() {
    
            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};
    
            protobuf.Timestamp = (function() {
    
                /**
                 * Properties of a Timestamp.
                 * @memberof google.protobuf
                 * @interface ITimestamp
                 * @property {number|Long|null} [seconds] Timestamp seconds
                 * @property {number|null} [nanos] Timestamp nanos
                 */
    
                /**
                 * Constructs a new Timestamp.
                 * @memberof google.protobuf
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Timestamp seconds.
                 * @member {number|Long} seconds
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
                /**
                 * Timestamp nanos.
                 * @member {number} nanos
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.nanos = 0;
    
                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 * @returns {google.protobuf.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };
    
                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                    return writer;
                };
    
                /**
                 * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.seconds = reader.int64();
                                break;
                            }
                        case 2: {
                                message.nanos = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Timestamp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                            return "seconds: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };
    
                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Timestamp)
                        return object;
                    var message = new $root.google.protobuf.Timestamp();
                    if (object.seconds != null)
                        if ($util.Long)
                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                        else if (typeof object.seconds === "string")
                            message.seconds = parseInt(object.seconds, 10);
                        else if (typeof object.seconds === "number")
                            message.seconds = object.seconds;
                        else if (typeof object.seconds === "object")
                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.seconds = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                    }
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (typeof message.seconds === "number")
                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                        else
                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };
    
                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                /**
                 * Gets the default type url for Timestamp
                 * @function getTypeUrl
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.Timestamp";
                };
    
                return Timestamp;
            })();
    
            return protobuf;
        })();
    
        return google;
    })();

    return $root;
});
