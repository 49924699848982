// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBLg9p8OHsvt-iFufkK9Qf_kDogKxTgmFQ",
  authDomain: "ground-nsm-test.web.app",
  projectId: "ground-nsm-test",
  storageBucket: "ground-nsm-test.firebasestorage.app",
  messagingSenderId: "631024831887",
  appId: "1:631024831887:web:429c0cfd839574406b4ffa",
  measurementId: "G-P8WYM9L762"
};
