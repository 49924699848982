<!--
  Copyright 2020 The Ground Authors.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<form [formGroup]="taskGroup" class="question-container">
  <mat-icon cdkDragHandle class="drag-icon">drag_handle</mat-icon>
  <div class="question-section">
    <mat-form-field class="question" appearance="fill">
      <mat-label>Question</mat-label>
      <input
        #questionInput
        matInput
        formControlName="label"
        autocomplete="off"
        (focus)="onTaskFocus()"
        (blur)="onLabelBlur()"
      />
      <mat-error *ngIf="labelControl.touched && labelControl.invalid">
        <ng-container *ngIf="labelControl.getError('required')">
          Question is required
        </ng-container>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="task-type" appearance="outline">
      <mat-select
        formControlName="selectTaskOption"
        class="task-type-select"
        (selectionChange)="onTaskTypeSelect($event.value)"
        (openedChange)="onTaskFocus()"
      >
        <mat-select-trigger>
          <mat-icon class="icon-task-type">{{
            getSelectTaskType().icon
          }}</mat-icon>
          <span> {{ getSelectTaskType().label }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let task of selectTaskOptions" [value]="task">
          <mat-icon>{{ task.icon }}</mat-icon>
          <span>{{ task.label }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="expanded">
    <div
      *ngIf="taskOptions?.options"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <div
        *ngFor="
          let option of taskOptions?.options;
          let i = index;
          trackBy: trackByFn
        "
        cdkDrag
      >
        <ground-option-editor
          [label]="option.label"
          [code]="option.code"
          [index]="i"
          (update)="onOptionUpdate($event, i)"
          (delete)="onOptionDelete(i)"
        ></ground-option-editor>
      </div>
      <button
        mat-button
        type="button"
        (click)="onAddOption()"
        color="primary"
        class="add-option"
      >
        Add option
      </button>
    </div>
    <hr class="horizontal-line" />
    <div
      (keydown.Tab)="onTaskBlur()"
      class="question-actions"
    >
      <button type="button" mat-icon-button (click)="onTaskDelete()">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <span class="vertical-divider"></span>
      <div class="required-toggle">
        <span class="required-label">Required</span>
        <mat-slide-toggle formControlName="required" color="primary">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</form>
